import React, { useContext } from 'react';
import { Context } from '../../../../AppContext';

function Calltoaction() {
  const { location } = useContext(Context);
  const contact = JSON.parse(location.contact);

  return (
    <div className="call-to-action-area">
      <div className="container">
        <div className="call-to-action style-two">
          <div className="cta-content">
            <h3 className="title" style={{marginTop: '10px'}}>Connect With Our Agent</h3>
            <a className="btn btn-white" style={{color: 'white', borderRadius: '3px'}} href={`tel:${contact.phone}`}>Make a Call</a>
            <a className="btn btn-white mb-sm-0 mr-xl-3 mr-0" style={{color: 'white', borderRadius: '3px'}} href={`mailto:${contact.email}`}>Email Us</a>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Calltoaction