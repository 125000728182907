import React, { useEffect, useState, useContext } from 'react';
import Error from './error';
import { Context } from "./AppContext";
import PageLoader from './PageLoader';

import './App.css';
import './Templates/Microsite-1/sass/style.scss'
import Microsite_1 from './Templates/Microsite-1/Microsite-1'; // ThemeForest - id = 1
import Microsite_2 from './Templates/Microsite-2/src'; // Quarter - id = 2
import Microsite_3 from './Templates/Microsite-3/src'; // Mindgrand - id = 3

function MainContent() {
  const {
    amenitiesObj,
    location,
      setLocationAmenities,
      setLocationId,
    template,
    units,
    unitType,
      setUpdatedUnits,
    getAmenities,
    getLocations,
    getTours,
    getTourTypes,
    getUnits,
    getUnitType,
  } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);

  /* eslint-disable */
  // Sets locationId via url then calls various queries.
  useEffect(() => {
    setLocationId(window.location.pathname.split("/")[2]);
    const location =window.location.pathname.split("/")[2]
    if(location ){
      getLocations(location)
      getUnitType(location)
      getUnits(location)
      getAmenities()
      getTours(location)
      getTourTypes(location)
    }
  }, []);
  /* eslint-enable */

  // checks amenities associated with a unit or property, then connects the name of the amenity with the id and returns object.
  const checkAmenities = (amenityId, isLoc = true) => {
    const amenity = isLoc ? amenitiesObj.find(a => a.node.dbId === amenityId) : amenitiesObj.find(a => a.node.id === amenityId);
    return amenity.node.name;
  }

  // Checks query returns
  const checkLoader = () => {
    if(
      location !== null &&
      units !== null &&
      unitType !== null &&
      amenitiesObj !== null &&
      JSON.parse(localStorage.getItem("orgDetails")) !== undefined
    ) {
      const locAmenities = location.amenities !== null ? location.amenities.map(a => checkAmenities(a)) : "";
      if(units !== null) {
        units.forEach(u => {
          let match = unitType.filter(id => (id.node.locationId === u.node.locationId) && (id.node.sqft === u.node.sqft));
          const amenities = u.node.amenities !== null ? u.node.amenities.map(a => checkAmenities(a, false)) : "";
          const floorPlan = match[0];
          const updated = {...u,  floorPlan, amenities};
          setUpdatedUnits(prevState => [...prevState, updated].slice(0, units.length));
        });
        setLocationAmenities(locAmenities);;
        setIsLoading(false);
        document.title = location.name;
      }
    }
  };

  const renderTemplate = () => {
    switch(template) {
      case 1:
        return <Microsite_1 location={location}/>
      case 2:
        return <Microsite_2 location={location}/>
      case 3:
        return <Microsite_3 location={location}/>
      default:
        return <Error/>
    } 
  };

  /* eslint-disable */
  useEffect(() => {
    checkLoader();
  }, [units, location, unitType, amenitiesObj, isLoading]);
  /* eslint-enable */

  return (
    <>
      {
        (isLoading || JSON.parse(localStorage.getItem("orgDetails")) === undefined) ?
        <PageLoader/>
        :
        renderTemplate()
      }
    </>
  );
}

export default MainContent;