import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button, Divider, Header, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'
import DayPickerInput from "react-day-picker/DayPickerInput";
import { optionsCountry, optionsMonth, optionsYear, optionsState, formatPhoneNumber } from '../../utils/constants.js'

function Income(props) {
    const [disableMiddle, setDisableMiddle] = useState(false);
    const [additionalAdd, setAdditionalAdd] = useState(false);
    const [Income, setIncome] = useState({
        Additional: []
    });


    useEffect(() => {
        if (performance.navigation.type === 1) {
            console.log(localStorage)
            const Income = localStorage.getItem('Income')
            Income && setIncome(JSON.parse(Income))
        } else {
            console.log(localStorage)
            const Income = localStorage.getItem('Income')
            Income && setIncome(JSON.parse(Income))
        }
    }, []);
    // Effect handler to set local storage
    useEffect(() => {
        const itemToSave = JSON.stringify(Income)
        if (Object.keys(itemToSave).length !== 0) {
            localStorage.setItem('Income', itemToSave)
        }
    }, [Income]);

    const addAdditionalLocation = () => {
        const newObj = {
            "amount": '',
            "name": '',
        }
        if (!Income.Additional || Income.Additional.length == 0) {
            setIncome(prev => ({
                ...prev,
                Additional: [newObj]
            }))
        }
        else {
            setIncome({
                ...Income,
                Additional: [...Income.Additional, newObj]
            })

        }
    }


    const removeAdditionalLocation = (index) => {
        var { Additional } = Income
        var newCharges = Income.Additional.filter((charge, index2) => index2 !== index);
        Additional = newCharges
        setIncome(prev => ({
            ...prev,
            Additional: Additional
        }))
        // setOccupantInfo({ Occupants })
        if (Additional.length == 0) {
            setAdditionalAdd(false)
        }
    }

    const handleChangeAdd = (e, index, name) => {
        const { Additional } = Income;
        Additional[index] = {
            ...Additional[index],
            [name]: e.target.value
        };
        setIncome(prev => ({
            ...prev,
            Additional: Additional
        }))
    };

    useEffect(() => {
        if (disableMiddle == true) {
            props.setDataObject(prevState => ({
                ...prevState,
                ["middleName"]: ''
            }));

        }
    }, [disableMiddle]);

    const handleChange = e => {
        const { name, value } = e.target;
        setIncome(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeSelect = (name, value) => {
        // const { name, value } = e.target;
        console.log(name, value)
        setIncome(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const Stepper = () => {
        props.setDataObject(prevState => ({ ...prevState, Page6: Income }))
        props.setAppStep(7)
    }

    


    return (
        <>
            <div>
                <h1 class="section__heading">Your income</h1>

                <Form>
                    <Form.Field>
                        <label>Employer Name</label>
                        <input name="employerName" value={Income.employerName} placeholder='Name' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Address 1</label>
                        <input disabled={disableMiddle} value={Income.employerAddress1} name="employerAddress1" placeholder='Address' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Address 2</label>
                        <input disabled={disableMiddle} value={Income.employerAddress2} name="employerAddress2" placeholder='Address' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>City</label>
                        <input disabled={disableMiddle} value={Income.employerCity} name="employerCity" placeholder='City' onChange={handleChange} />
                    </Form.Field>
                    <Form.Select
                        fluid
                        search
                        label='State'
                        name="employerState"
                        options={optionsState}
                        value={Income.employerState}
                        placeholder="State"
                        onChange={(e, { value }) => handleChangeSelect("employerState", value)}
                    />
                    <Form.Field>
                        <label>Zip</label>
                        <input onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} disabled={disableMiddle} value={Income.employerZip} name="employerZip" placeholder='Zip' onChange={handleChange} />
                    </Form.Field>

                    <Form.Select
                        fluid
                        search
                        label='Country'
                        name="employerCountry"
                        options={optionsCountry}
                        value={Income.employerCountry}
                        placeholder="Country"
                        onChange={(e, { value }) => handleChangeSelect("employerCountry", value)}
                    />
                    <Form.Field>
                        <label>Phone</label>
                        <input maxLength="10" onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} disabled={disableMiddle} value={formatPhoneNumber(Income.employerPhone)} name="employerPhone" placeholder='Phone' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Monthly Salary</label>
                        <input onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} disabled={disableMiddle} value={Income.employerSalary} name="employerSalary" placeholder='Salary' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Position Held</label>
                        <input disabled={disableMiddle} value={Income.employerPosition} name="employerPosition" placeholder='Position' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Years Worked</label>
                        <input onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} disabled={disableMiddle} value={Income.employerWorked} name="employerWorked" placeholder='Worked' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Supervisor Name</label>
                        <input disabled={disableMiddle} value={Income.employerSupervisorName} name="employerSupervisorName" placeholder='Name' onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Supervisor Email</label>
                        <input disabled={disableMiddle} value={Income.employerSupervisorEmail} name="employerSupervisorEmail" placeholder='Email' onChange={handleChange} />
                    </Form.Field>

                    <Divider />
                    <Header>I have additional income</Header>
                    {/* <Checkbox name="noMiddle" label='I have additional income' checked={additionalAdd} onChange={() => setAdditionalAdd(!additionalAdd ? true : false)} /> */}

                    {
                        Income && Income.Additional && Income.Additional.map((app, index) => {
                            return (
                                <>
                                    <Form>
                                        <Header>Source</Header>
                                        <Form.Field>
                                            <label>Amount</label>
                                            <input onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} name={`amount`} value={app.amount} placeholder='Amount' onChange={(e) => handleChangeAdd(e, index, "amount")} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Name</label>
                                            <input name={`name`} value={app.name} placeholder='Name' onChange={(e) => handleChangeAdd(e, index, "name")} />
                                        </Form.Field>
                                    </Form>
                                    <Label as={'button'} onClick={() => removeAdditionalLocation(index)}>
                                        <Icon name='minus' /> Remove Icome
                                    </Label>
                                    <Divider />
                                </>
                            )
                        })
                    }


                    <>
                        <Label as={'button'} onClick={() => addAdditionalLocation()}>
                            <Icon name='add' /> Add Income
                        </Label>
                    </>

                </Form>
                <br></br>
                <div style={{ float: 'right' }}>
                    <Button icon labelPosition='left' onClick={() => props.setAppStep(5)}>
                        <Icon name='left arrow' />
                        Previous
                    </Button>
                    <Button icon labelPosition='right' onClick={() => Stepper()} >
                        Save & Next
                        <Icon name='right arrow' />
                    </Button>
                </div>

            </div>
        </>
    );
}

export default Income