import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../AppContext';
import { Link } from 'react-router-dom';

function Page_header(props) {
  const { header, subheader, } = props;
  const { photosArray } = useContext(Context);
  const [headerPhoto, setHeaderPhoto] = useState(photosArray);

  useEffect(() => {
    if (photosArray.length >= 4) {
      setHeaderPhoto(props.from === 'units' ? photosArray[1] : (props.from === 'contact') ? photosArray[2] : photosArray[3]);
    } else if (photosArray.length === 3) {
      setHeaderPhoto(props.from === 'units' ? photosArray[1] : (props.from === 'contact') ? photosArray[2] : photosArray[0]);
    } else if (photosArray.length === 2) {
      setHeaderPhoto(props.from === 'details' ? photosArray[0] : photosArray[1]);
    } else {
      setHeaderPhoto(photosArray[0])
    }
  },[photosArray]);

  const inlineStyle = {
      backgroundImage: `url(${headerPhoto})`
  }
  return (
    <>
      {
        <div className="breadcrumb-area jarallax" style= { inlineStyle }>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner">
                  <h1 className="page-title">{header}</h1>
                  <ul className="page-list">
                    <li><Link to="/">Home</Link></li>
                    <li>{subheader}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default Page_header