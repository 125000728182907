import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Context } from "../AppContext";
import {
  googleCalendarTourSchema,
  leadSchema,
} from "../Validations/UserValidations";
import { MICROSITE_INFO } from "../utils/constants";
import { getClient } from "../init-apollo-googleFn";
import { createTourGql, createLeadGql } from "../store/schemas/location";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import { CustomDropDown } from "./CustomDropDown";
import { Loader } from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { googleEventsGql, tourAgentsGql } from "../store/schemas/calendar";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  unit: "",
  tourType: "",
  tourAgent: "",
  timePreference: "",
  dateTimeStart: new Date(),
  dateTimeEnd: new Date(),
};

export default function TourForm(props) {
  /* eslint-disable */
  const {
    updatedUnits,
    locationId,
    tourTypes,
    calendarEvents,
    getEvents,
    getTourTypes,
  } = useContext(Context);
  const [ageCheck, setAgeCheck] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString("en-US")
  );
  const [isTourReady, setIsTourReady] = useState(false);
  const [leadId, setLeadId] = useState("");
  const orderedUnits = [...updatedUnits].sort(
    (a, b) => a.node.number - b.node.number
  );
  const [leadStatus, setLeadStatus] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [isStep, setIsStep] = useState("open");
  const [calendarTouched, setCalendarTouched] = useState(false);
  const [tourValues, setTourValues] = useState(null);
  const [hours, setHours] = useState({});
  const [tourAgents, setTourAgents] = useState([]);
  const [selectedTourAgent, setSelectedTourAgent] = useState(null);
  const [selectedDateForCalendar, setSelectedDateForCalendar] = useState(
    new Date()
  );
  const [blockedHours, setBlockedHours] = useState([]);
  const [loader, setLoader] = useState(false);
  /* eslint-enable */

  const micrositeClient = getClient(MICROSITE_INFO);

  const createHours = () => {
    // create hours between 7 till 17 each 30 mins

    const start = 7;
    const end = 17;
    const calculateArrayLength = (end - start) * 2;
    let currentHour = 7; // 7:00 AM
    let tourHours = {};
    let isHalf = false; // timeline starting at time.

    for (let i = 0; i <= calculateArrayLength; i++) {
      let currentMinute;
      if (isHalf) {
        currentMinute = "30";
      } else {
        currentMinute = "00";
      }

      const meridien = currentHour > 11 ? "PM" : "AM";
      const make12Hour = currentHour > 12 ? currentHour - 12 : currentHour;
      const shownHour = make12Hour < 10 ? `0${make12Hour}` : make12Hour;

      if (isHalf) {
        currentHour += 1;
        isHalf = false; // for the next loop
      } else {
        isHalf = true; // for the next loop
      }

      tourHours[`${shownHour}:${currentMinute}${meridien}`] = [];
    }

    setHours(tourHours);
  };

  const createTour = (obj) => {
    try {
      const time1 = obj.timePreference.slice(
        obj.timePreference.length - 2,
        obj.timePreference.length
      );
      const time0 = obj.timePreference.slice(0, obj.timePreference.length - 2);
      micrositeClient
        .mutate({
          mutation: createTourGql,
          variables: {
            input: {
              leadId: obj.leadId,
              name: obj.firstName + " " + obj.lastName,
              tourType: obj.tourType,
              date: selectedDate,
              time: `${time0} ${time1}`,
              duration: "30",
              location: locationId,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              calendarTimeZone:
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        })
        .then((response) => {
          setLoader(false);
          setIsTourReady(false);
          setToastMessage(
            toast.success(
              "Tour Request submitted. An agent will email you soon."
              )
              );
            })
            .catch((e) => {
          setLoader(false);
          setToastMessage(
            toast.warning(
              "We were unable to schedule your tour, but we have your contact information and will reach out to you. Our apologies."
              )
              );
              console.log(e);
              setIsTourReady(false);
            });
          } catch (e) {
      setLoader(false);
      if (leadStatus === "Success") {
        setToastMessage(
          toast.warning(
            "We were unable to schedule your tour, but we have your contact information and will reach out to you. Our apologies."
          )
        );
      } else {
        setToastMessage(
          toast.error("We were unable to retrieve your data, our apologies.")
        );
      }
      console.log(e);
    }
  };

  const createLead = (obj) => {
    try {
      setLoader(true);
      micrositeClient
        .mutate({
          mutation: createLeadGql,
          variables: {
            input: {
              email: obj.email,
              lastName: obj.lastName,
              firstName: obj.firstName,
              status: "Tour",
              source: "Microsite",
              phoneMobile: obj.phoneNumber,
              location: locationId,
              assign: obj.tourAgent,
              preference: {
                unit: obj.unit,
              },
            },
          },
        })
        .then((response) => {
          setLeadId(response.data.createLead.lead.id);
          setIsTourReady(true);
          setSelectedDate("");
          if (
            response.data.createLead.response === "200" &&
            response.data.createLead.lead.id
          ) {
            setLeadStatus("Success");
            createTour({ ...obj, leadId: response.data.createLead.lead.id });
          } else if (
            response.data.createLead.response !== "200" &&
            response.data.createLead.lead.id
          ) {
            setLoader(false);
            setLeadStatus("Exists");
          } else {
            setLoader(false);
            setLeadStatus("Failed");
          }
        })
        .catch((e) => {
          if (
            e.errors.some(
              (t) => t.message === "Lead already exists with current email."
            )
          ) {
            alert("Lead already exists with current email.");
          }
          console.log(e);
        });
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
      <div
        className="day-picker-popup"
        style={{ marginTop: -369, marginLeft: -42 }}
        {...props}
      >
        <div className={classNames.overlay}>{children}</div>
      </div>
    );
  }

  async function getTourAgents() {
    try {
      const response = await micrositeClient.query({
        query: tourAgentsGql,
        variables: { location: locationId },
        fetchPolicy: "no-cache",
      });

      if (response.data.tourAgents.edges) {
        setTourAgents(response.data.tourAgents.edges);
      }

      console.log({ response, orderedUnits });
    } catch (error) {
      //
    }
  }

  async function getGoogleCalendarEvents() {
    try {
      const res = await micrositeClient.query({
        query: googleEventsGql,
        variables: {
          location: locationId,
          startDate: moment(selectedDateForCalendar).format("YYYY-MM-DD"),
          endDate: moment(selectedDateForCalendar)
            .add(1, "day")
            .format("YYYY-MM-DD"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          tourAgent: selectedTourAgent,
        },
        fetchPolicy: "no-cache",
      });

      if (res?.errors) {
        console.warn(res.errors);
      }
      console.log({ googleEvents: res.data.googleEvents });

      let blockedHours = [];
      res.data?.googleEvents?.edges.map(({ node: { start, end } }) => {
        console.log({ start, end });
        // Start Hour Template
        const startTime = moment(start.dateTime);
        const eventStartHour = startTime.format("h");
        const eventStartMinute = startTime.minutes();
        const eventStartA = startTime.format("A");

        const startTimeData = createHourTemplate({
          hour: eventStartHour,
          minute: eventStartMinute,
          a: eventStartA,
        });

        blockedHours.push(startTimeData.index);

        // End Hour Template
        const endTime = moment(end.dateTime);
        const eventEndHour = endTime.format("h");
        const eventEndMinute = endTime.minutes();
        const eventEndA = endTime.format("A");

        const endTimeData = createHourTemplate({
          hour: eventEndHour,
          minute: eventEndMinute,
          a: eventEndA,
        });
        blockedHours.push(endTimeData.index);

        // if not at the same hour
        if (startTimeData.hours !== endTimeData.hours) {
          let start24Hr =
            startTimeData.meridien === "PM"
              ? startTimeData.hours + 12
              : startTimeData.hours;
          let end24Hr =
            endTimeData.meridien === "PM"
              ? endTimeData.hours + 12
              : endTimeData.hours;

          const diff = parseInt(end24Hr) - parseInt(start24Hr);

          let diffWithHalf = diff * 2;
          let isHalf = startTimeData.minutes === "30";
          if (isHalf) diffWithHalf -= 1;

          let currentHour = parseInt(start24Hr);
          let blockedHoursByDiff = [];
          new Array(diffWithHalf).fill("?").map(() => {
            let newHour;
            let newMinute;

            if (isHalf) {
              currentHour = parseInt(currentHour) + 1;
              newHour = currentHour;
              newMinute = "00";
              isHalf = false;
            } else {
              isHalf = true;
              newMinute = "30";
              newHour = currentHour;
            }

            let meridien = currentHour > 11 ? "PM" : "AM";
            newHour = newHour < 10 ? `0${newHour}` : newHour;
            blockedHoursByDiff.push(`${newHour}:${newMinute}${meridien}`);
          });

          blockedHours = [...blockedHours, ...blockedHoursByDiff];
          console.log({
            blockedHoursByDiff,
            diff,
            blockedHours,
            startTimeData,
            endTimeData,
          });
        }
        console.log({ startTimeData, endTimeData });
      });

      const shouldBeLinkedToAccount = res?.errors
        ? res.errors.some(
            (err) => err.message === "Calendar is not linked to account."
          )
        : false;
      console.log({ shouldBeLinkedToAccount, blockedHours });
      setBlockedHours(blockedHours);
    } catch (error) {
      //
      console.log(error);
    }
  }

  /* eslint-disable */
  const tourLeadSubmit = async (values) => {
    const isValid = await leadSchema.isValid();
    setTourValues(values);
    createLead(values);
  };

  useEffect(() => {
    createHours();
    getTourAgents();
    getTourTypes(locationId);
  }, []);

  useEffect(() => {
    return toastMessage;
  }, [toastMessage]);

  useEffect(() => {
    getGoogleCalendarEvents();
  }, [selectedDateForCalendar, selectedTourAgent]);
  /* eslint-enable */

  return (
    <div className="tour-form-container">
      <ToastContainer position="bottom-center" />
      {
        <Formik
          initialValues={initialValues}
          validationSchema={googleCalendarTourSchema}
          onSubmit={(values, { resetForm }) => {
            tourLeadSubmit(values);
            resetForm();
          }}
        >
          {(formik) => {
            if (formik.values.tourAgent !== selectedTourAgent) {
              setSelectedTourAgent(formik.values.tourAgent);
              if (formik.values.timePreference !== "")
                formik.setFieldValue("timePreference", "");
            }

            if (formik.values.dateTimeStart !== selectedDateForCalendar) {
              setSelectedDateForCalendar(formik.values.dateTimeStart);
              if (formik.values.timePreference !== "")
                formik.setFieldValue("timePreference", "");
            }

            return (
              <Form
                id="tour-form"
                className="contact-form-wrap"
                style={{ background: "#F6F7FB" }}
              >
                <h4>Schedule a tour</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <TextField
                      label="First Name"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField label="Last Name" name="lastName" type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <TextField label="Email" name="email" type="email" />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <CustomDropDown
                      label="Tour Agent"
                      name="tourAgent"
                      data="tourAgent"
                      obj={tourAgents}
                      text="Tour Agent:"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label for="units" className="rld-single-input">
                      Choose a date:
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginLeft: "15px",
                    }}
                    name="dateTimeStart"
                    {...formik.touched}
                    className={`${
                      selectedDate === "" &&
                      calendarTouched &&
                      "date-form-error-input"
                    }`}
                  >
                    <DayPickerInput
                      className="day-picker-input"
                      overlayComponent={CustomOverlay}
                      placeholder="DD/MM/YYYY"
                      style={{ background: "#f3f3f3" }}
                      value={new Date(
                        formik.values.dateTimeStart
                      ).toLocaleDateString()}
                      onDayChange={(day) => {
                        formik.setFieldValue("dateTimeStart", day);
                        formik.setFieldValue("dateTimeEnd", day);
                        setSelectedDate(day.toLocaleDateString("en-US"));
                      }}
                      dayPickerProps={{ disabledDays: { before: new Date() } }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <CustomDropDown
                      label="Time Preference"
                      name="timePreference"
                      data="hours"
                      obj={hours}
                      filter={blockedHours}
                      text="Choose a time:"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <CustomDropDown
                      label="Tour Type"
                      name="tourType"
                      data="tourType"
                      obj={tourTypes}
                      text="Tour Type:"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <CustomDropDown
                      label="Unit"
                      name="unit"
                      data="orderedUnits"
                      obj={orderedUnits}
                      text="Choose a unit to tour:"
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-sm-6">
                    <label for="units" className="rld-single-input">
                      Suggest a finish date:
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginLeft: "15px",
                    }}
                    name="dateTimeEnd"
                    onClick={() => setCalendarTouched(true)}
                    className={`${
                      selectedDate === "" &&
                      calendarTouched &&
                      "date-form-error-input"
                    }`}
                  >
                    <DayPickerInput
                      className="day-picker-input"
                      overlayComponent={CustomOverlay}
                      placeholder="DD/MM/YYYY"
                      style={{ background: "#f3f3f3" }}
                      value={new Date(
                        formik.values.dateTimeEnd
                      ).toLocaleDateString()}
                      onDayChange={(day) => {
                        formik.setFieldValue("dateTimeEnd", day);
                      }}
                      dayPickerProps={{ disabledDays: { before: new Date() } }}
                    />
                  </div>
                </div> */}
                {selectedDate === "" && calendarTouched && (
                  <div
                    className="date-form-error-message"
                    style={{ textAlign: "right" }}
                  >
                    Please select a date
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12">
                    <label
                      style={{
                        display: "block",
                        width: "240px",
                        margin: "10px auto 0 auto",
                      }}
                    >
                      Are you at least 18 years of age?
                    </label>
                    <input
                      onClick={() => setAgeCheck(!ageCheck)}
                      type="checkbox"
                      style={{
                        transform: "scale(1.4, 1.4)",
                        margin: "0 auto 2px auto",
                        display: "block",
                        width: "17.23px",
                      }}
                    />
                  </div>
                </div>
                <div className="btn-wrap text-center">
                {loader ?
                  <div style={{transform: 'scale(0.4)'}}>
                    <Loader style={{margin: '0 auto'}}/>
                  </div>
                :
                  <button
                    style={props.submitStyle}
                    disabled={!ageCheck}
                    className="btn"
                    type="click"
                  >
                    Submit
                  </button>
                }
                </div>
              </Form>
            );
          }}
        </Formik>
      }
    </div>
  );
}

function createHourTemplate({ hour, minute, a }) {
  let startTimeIndex = `${hour < 10 ? `0${hour}` : hour}:30${a}`;
  let hours = hour < 10 ? `0${hour}` : hour;
  let minutes = "30";
  let meridien = a;

  if (minute > 30) {
    startTimeIndex = `${hour + 1 < 10 ? `0${hour + 1}` : hour + 1}:00${a}`;
    hours = hour + 1 < 10 ? `0${hour + 1}` : hour + 1;
    minutes = "00";
    meridien = a;
  } else if (minute < 30) {
    startTimeIndex = `${hour < 10 ? `0${hour}` : hour}:00${a}`;
    hours = hour < 10 ? `0${hour}` : hour;
    minutes = "00";
    meridien = a;
  }

  return {
    index: startTimeIndex,
    hours,
    minutes,
    meridien,
  };
}
