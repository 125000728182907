import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';
import '../../style.scss';

function Navbar() {
	const { location } = useContext(Context);
	const contact = JSON.parse(location.contact);
	const address = JSON.parse(location.addresses)[0];
	const { socials } = contact;
	const [showMobile, setShowMobile] = useState(false);

	return (
		<div>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
					<div className="row">
						<div className="col-md-7">
							<div className="ltn__top-bar-menu" style={{height: '40px'}}>
								<ul style={{marginTop: '10px'}}>
									<li><a href={`mailto:${contact.email}`}><i className="icon-mail" />{contact.email}</a></li>
									<li><Link to="/location"><i className="icon-placeholder" />{address.streetOne}, {address.city}</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-md-5">
							<div className="top-bar-right text-end">
								<div className="ltn__top-bar-menu">
									<div className="ltn__social-media">
										{socials && (socials.facebook || socials.twitter || socials.linkedIn || socials.instagram || socials.yelp) &&
											<ul>
												{socials.facebook &&      
													<li className='contact-link'>
														<a href={`http://${socials.facebook}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-facebook contact-icon" /></a>
													</li>
												}
												{socials.twitter &&      
													<li className='contact-link'>
														<a href={`http://${socials.twitter}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-twitter contact-icon" /></a>
													</li>
												}
												{socials.linkedIn &&      
													<li className='contact-link'>
														<a href={`http://${socials.linkedIn}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-linkedin contact-icon" /></a>
													</li>
												}
												{socials.instagram &&      
													<li className='contact-link'>
														<a href={`http://${socials.instagram}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-instagram contact-icon" /></a>
													</li>
												}
												{socials.yelp &&      
													<li className='contact-link'>
														<a href={`http://${socials.yelp}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-yelp contact-icon" /></a>
													</li>
												}
											</ul>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="site-logo-wrap">
								<div className="site-logo go-top">
									<Link	Link to="/">{location.name}</Link>
								</div>
								<div className="get-support clearfix d-none">
									<div className="get-support-icon">
										<i className="icon-call" />
									</div>
									<div className="get-support-info">
										<h6>Get Support</h6>
										{contact.phone &&
											<h4><a href={`tel:${contact.phone}`}>{`+${contact.phone[0]} (${contact.phone.slice(1, 4)}) ${contact.phone.slice(4, 7)} ${contact.phone.slice(7)}`}</a></h4>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="col header-menu-column">
							<div className="header-menu d-none d-xl-block">
								<nav>
									<div className="ltn__main-menu go-top">
										<ul>
											<li>
												<Link to="/">
													Home
												</Link>
											</li>
											<li><Link to="/units">
												Units
											</Link>
											</li>
											<li>
												<Link to="/contact">Contact</Link>
											</li>
										</ul>
									</div>
								</nav>
							</div>
						</div>
						<div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
							<div className="mobile-menu-toggle d-xl-none" onClick={() => setShowMobile(!showMobile)}>
								<a className="ltn__utilize-toggle">
								<svg viewBox="0 0 800 600">
									<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
									<path d="M300,320 L540,320" id="middle" />
									<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
								</svg>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			</header>
			{showMobile &&
				<div className="ltn__utilize-mobile-menu">
					<div className="ltn__utilize-menu-inner ltn__scrollbar">
						<div className="ltn__utilize-menu active">
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/units">Units</Link>
							</li>
							<li>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
						</div>
						<div className="ltn__social-media-2">
						<ul>
							<li><a href="#" title="Facebook"><i className="fa fa-facebook-f" /></a></li>
							<li><a href="#" title="Twitter"><i className="fa fa-twitter" /></a></li>
							<li><a href="#" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
							<li><a href="#" title="Instagram"><i className="fa fa-instagram" /></a></li>
						</ul>
						</div>
					</div>
				</div>
			}
		</div>
  );
}


export default Navbar