import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';

function Footer_v1() {
	const { location } = useContext(Context);
	const contact = JSON.parse(location.contact);
	const address = JSON.parse(location.addresses)[0];
	const { socials } = contact;
	const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));

	useEffect(() => {
		const $ = window.$;
		
		let publicUrl = process.env.PUBLIC_URL+'/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";
	
		document.body.appendChild(minscript);
	
		 $('.go-top').find('a').on('click', function () {
	
			$(".quarter-overlay").fadeIn(1);
	
				$(window).scrollTop(0);
	
			setTimeout(function(){
					$(".quarter-overlay").fadeOut(300);
				}, 800);
	
		});
	
	
		$(document).on('click','.theme-btn-1 ', function(){ 
			$( 'div' ).removeClass( 'modal-backdrop' );
			$( 'div' ).removeClass( 'show' );
			$( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
		});
	},[])

    return (
			orgDetails !== undefined &&
			<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container">
				      <div className="row">
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12" style={{height: '200px'}}>
				          <div className="footer-widget footer-about-widget">
				              <h4 style={{fontSize: '32px'}} className='footer-title'><Link to='/'>{location.name}</Link></h4>
				            <div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-placeholder" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p>{address.city}, {address.state}, {address.country}</p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				            <div className="ltn__social-media mt-20">
											<div className="ltn__social-media">
												{socials && (socials.facebook || socials.twitter || socials.linkedIn || socials.instagram || socials.yelp) &&
													<ul>
														{socials.facebook &&      
															<li className='contact-link'>
																<a href={`http://${socials.facebook}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-facebook contact-icon" /></a>
															</li>
														}
														{socials.twitter &&      
															<li className='contact-link'>
																<a href={`http://${socials.twitter}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-twitter contact-icon" /></a>
															</li>
														}
														{socials.linkedIn &&      
															<li className='contact-link'>
																<a href={`http://${socials.linkedIn}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-linkedin contact-icon" /></a>
															</li>
														}
														{socials.instagram &&      
															<li className='contact-link'>
																<a href={`http://${socials.instagram}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-instagram contact-icon" /></a>
															</li>
														}
														{socials.yelp &&      
															<li className='contact-link'>
																<a href={`http://${socials.yelp}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-yelp contact-icon" /></a>
															</li>
														}
													</ul>
												}
											</div>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">

				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            
				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
				          <div className="footer-widget footer-newsletter-widget">
										<h4 className="footer-title"><Link to="/contact">Contact</Link></h4>
				            <div className="footer-menu go-top">
				              <ul>
												<li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href={`tel:${contact.phone}`}>{`+1 (${contact.phone.slice(0, 3)}) ${contact.phone.slice(3, 6)} ${contact.phone.slice(6)}`}</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-mail" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
					<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
						<div className="container-fluid ltn__border-top-2">
							<div className="row">
								<div className="col-md-6 col-12">
									<div className="ltn__copyright-design clearfix">
									<p>All Rights Reserved @ {orgDetails.name}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
    );
}


export default Footer_v1