import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';

function CallToActonV1() {
	const { location } = useContext(Context);
	return (
		<div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" >
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
							<div className="coll-to-info text-color-white">
								<h1>Looking for a dream {location.type === "Office" ? "Office" : "Home"}?</h1>
								<p>We can help you realize that dream</p>
							</div>
							<div className="btn-wrapper go-top">
								<Link
									className="btn btn-effect-3 btn-white"
									style={{color: 'white'}}
									to="/contact"
								>
									Contact Us <i className="icon-next" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CallToActonV1