import React, { useContext } from 'react';
import { Context } from '../../../../AppContext';
import { Link } from 'react-router-dom';

function Navbar () {
  const { location } = useContext(Context);

  return (
    <div>
      <div className="navbar-area">
        <nav className="navbar navbar-area navbar-expand-lg">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo readeal-top">
              <Link to="/" className="nav-text-bordered" style={{fontSize: "36px"}}>{location.customId}</Link>
            </div>
            <div className="collapse navbar-collapse" id="realdeal_main_menu">
              <ul className="navbar-nav menu-open readeal-top">
                <li className="current-menu-item">
                  <Link to="/" className="menu-item-has-children current-menu-item nav-text-bordered">Home</Link>
                </li>
                <li>
                  <Link to="/units" className="nav-text-bordered">Units</Link>
                </li>
                <li>
                  <Link to="/contact" className="nav-text-bordered">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar