import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';

function CategoryV2() {
	const { locationAmenities, updatedUnits } = useContext(Context);

	const sortedUnitAmenities = updatedUnits.map((k, i ) => {
    return k.amenities;
  }).sort((a, b) => {
    return b.length - a.length;
  })

  const miniArray = locationAmenities.length > 0 ? locationAmenities.slice(0, 8) : sortedUnitAmenities[0].slice(0, 8);

	return (
		miniArray.length > 0 &&
		<div className="ltn__category-area ltn__product-gutter section-bg-1--- pb-70">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h1 className="section-title">Our Amenities</h1>
						</div>
					</div>
				</div>
				<div className="row justify-content-center go-top">
					{miniArray.map((k,i) => {
						return (
							<div className="col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
									<Link to="/shop-grid">
										<span className="category-number">{i+1}</span>
										<span className="category-title">{k}</span>
									</Link>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default CategoryV2