import React, { useContext } from 'react';
import { Context } from '../../../../AppContext';
import placeholder from '../../../../assets/undraw/undraw_apartment.png';

function RoomSpacing() {
  const { updatedUnits, location } = useContext(Context);

  const filteredList = updatedUnits.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj.node.sqft).indexOf(obj.node.sqft) === pos;
  });

  const photosList = filteredList.map((k) => {
    if(k.node.photos){
      return k.node.photos[0] !== undefined ? k.node.photos : (location.photos !== "[]") ? JSON.parse(location.photos) : [placeholder] // get the first photo on the list if it's not undefined else get it from location.photos if ti's not an empty array else showing placeholder
    }
    else return []
  });

  return (
    <div className="apartments-area pd-top-100 pd-bottom-100">
      <div className="container">
        <div className="apartments-slider-2">
          { filteredList.map( ( item, i )=>
            <div key={ i } className="item">
              <div className="row">
                <div className="col-xl-6 col-lg-7">
                  <div className="thumb">
                    {(filteredList[i].node.photos !== "[]" && photosList[i] !== null) ? //get the picture if the filteredList is not an empty array and photosList is not null else showing placeholder 
                      <img src={photosList[i][Math.floor(Math.random() * photosList[i].length)]} height="422.5px" width="650px" alt="" />
                      :
                      <img src={placeholder} height="422.5px" width="650px" alt="" />
                    }
                  </div>
                </div>
                <div className="col-lg-6 align-self-end">
                  <div className="details room-spacing-card-height">
                    <span> {i + 1} </span>
                    {/* showing unit type and unit number */}
                    <h6 style={{fontSize: "24px"}}>{filteredList[i].node.unitType.type}: Unit {filteredList[i].node.number}</h6> 
                    <h2 className="title carousel-h2">
                      <i className="fa fa-arrows-alt carousel-icon-1" style={{color: "#fda94f"}}/>
                      {filteredList[i].node.sqft} Sq Ft.
                      <i className="fa fa-bed carousel-icon-2" style={{color: "#fda94f"}}/>
                      {filteredList[i].node.bedrooms} {filteredList[i].node.bedrooms > 1 ?   //show bedroom 
                                                        "Bedrooms" : "Bedroom"
                                                      }
                      <i className="fa fa-bath carousel-icon-2" style={{color: "#fda94f"}}/> 
                      {filteredList[i].node.bathrooms} {filteredList[i].node.bathrooms > 1 ? //show bathroom 
                                                          "Bathrooms" : "Bathroom"
                                                        }
                    </h2>
                    <div className="row">
                      {item.amenities !== "" &&  //show amenities of that unit
                        <>
                          <div className="col-sm-6">
                            { item.amenities.map( ( amenity, i )=>
                              <ul className="rld-list-style mb-3 mb-sm-0 carousel-ul">
                                {i % 2 === 0 && 
                                  <li key={ i }><i className="fa fa-check" /> {amenity} </li>
                                }
                              </ul>
                            ) }
                          </div>
                          <div className="col-sm-6">
                            { item.amenities.map( ( amenity, i )=>
                              <ul className="rld-list-style carousel-ul">
                                {i % 2 !== 0 && 
                                  <li><i className="fa fa-check" /> {amenity}</li>
                                }
                              </ul>
                            ) }
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) }

        </div>
        <div className="ap2-slider-controls">
          {/*slider-nav*/}
          <div className="rld-slider-extra slider-extra">
            <div className="text">
              <span className="first">01 </span>
              <span className="last" />
            </div>
            {/*text*/}
            <div className="ap2-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
              <span className="slider__label sr-only" />
            </div>
            <div className="slider-nav rld-control-nav" />
          </div>
          {/*slider-extra*/}
        </div>
      </div>
    </div>
  );
}


export default RoomSpacing