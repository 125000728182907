import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../AppContext';
import placeHolderPlanPic from '../../../../../assets/undraw/undraw_plan.png';
import MapContainer from '../../../../../shared_components/google-map';
import { Formik, Form } from 'formik';
import { TextField } from '../../../../../shared_components/TextField';
import { leadSchema } from '../../../../../Validations/UserValidations';
import { ToastContainer, toast } from 'react-toastify';
import { getClient } from '../../../../../init-apollo-googleFn';
import { MICROSITE_INFO } from '../../../../../utils/constants';
import { createLeadGql } from '../../../../../store/schemas/location';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function ShopDetails() {

	const { location, locationId, locationAmenities } = useContext(Context);
	const { socials } = JSON.parse(location.contact);
	const address = JSON.parse(location.addresses)[0];
	const selectedUnit = JSON.parse(localStorage.getItem('selectedUnit'));
	const photos = selectedUnit.node.photos;
	const floorPlanPic = selectedUnit.floorPlan && (selectedUnit.floorPlan.node.photos !== "[]" && selectedUnit.floorPlan.node.photos !== "\"\"") ? selectedUnit.floorPlan.node.photos.slice(4, selectedUnit.floorPlan.node.photos.length -4) : placeHolderPlanPic;
	const [toastMessage, setToastMessage] = useState(null);
	const [ageCheck, setAgeCheck] = useState(false);
	const [loader, setLoader] = useState(false);

	const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
			setLoader(true);
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: selectedUnit.node.id,
            }
          }
        }
      }).then(response => {
				setLoader(false);
				if (response.data.createLead.response === null && response.data.createLead.lead.id) {
					setToastMessage(toast.success("We've received your contact info. An agent will email you soon."));
				} else if (response.data.createLead.response !== null && response.data.createLead.lead.id) {
					setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
					setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e => console.log(e));
    }
    catch(e) {
			setLoader(false);
      console.log(e);
    }
  }

	  /* eslint-disable */
		useEffect(() => {
			return toastMessage;
		}, [toastMessage]);
	
		const leadFormSubmit = async(values) => {
			const isValid = await leadSchema.isValid();
			createLead(locationId, values);
		}

		useEffect(() => {
			const $ = window.$;
	
			$( 'body' ).addClass( 'body-bg' );
		},[])
		/* eslint-enable */

	return (
		<div className="ltn__shop-details-area pb-10">
			<ToastContainer position="bottom-center"/>
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-category">
								<Link className="bg-orange" to="#">For Rent</Link>
							</li>
						</ul>
						</div>
						<h1>{selectedUnit.node.type}</h1>
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> {location.name}, {address.city}</label>
						<h4 className="title-2">Description</h4>
						<p>{location.description}</p>
						{selectedUnit.description &&
							<p>{selectedUnit.description}</p>
						}
						<h4 className="title-2">Property Detail</h4>  
						<div className="property-detail-info-list section-bg-1 clearfix mb-60">                          
							<ul>
								<li><label>Floor:</label> <span>{selectedUnit.node.level}</span></li>
								<li><label>Area:</label> <span>{selectedUnit.node.sqft} sqft</span></li>
								<li><label>Bedrooms:</label> <span>{selectedUnit.node.bedrooms}</span></li>
								<li><label>Status:</label> <span>{selectedUnit.node.status&&selectedUnit.node.status[0].toUpperCase()}{selectedUnit.node.status&&selectedUnit.node.status.slice(1, selectedUnit.node.status.length)}</span></li>
							</ul>
							<ul>
								<li><label>Unit:</label> <span>{selectedUnit.node.number}</span></li>
								<li><label>Price:</label> <span>${selectedUnit.node.price}</span></li>
								<li><label>Bathrooms:</label> <span>{selectedUnit.node.bathrooms}</span></li>
							</ul>
						</div>
						{selectedUnit.amenities ?
							<>
								<h4 className="title-2 mb-10">Amenities</h4>
									<div className="property-details-amenities mb-60">
										<div className="ltn__menu-widget">
											{selectedUnit.amenities.map((k) => {
												return (
													<label style={{margin: '20px 20px 20px 20px'}} className="checkbox-item">{k}
														<input type="checkbox" defaultChecked="checked" disabled />
														<span className="checkmark" />
													</label>
												);
											})}
									</div>
								</div>
							</>
						: (locationAmenities.length > 0) &&
							<>
								<h4 className="title-2 mb-10">Amenities</h4>
									<div className="property-details-amenities mb-60">
										<div className="ltn__menu-widget">
											{locationAmenities.map((k) => {
												return (
													<label style={{margin: '20px 20px 20px 20px'}} className="checkbox-item">{k}
														<input type="checkbox" defaultChecked="checked" disabled />
														<span className="checkmark" />
													</label>
												);
											})}
									</div>
								</div>
							</>
						}
						<h4 className="title-2">Location</h4>
						<div className="property-details-google-map mb-60">
							<MapContainer lat={location.lat} lng={location.lng} inlineStyle={{height: '360px'}} />
						</div>
						<h4 className="title-2">Floor Plans</h4>
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="tab-content">
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
								<div className="col-lg-7">
									<div className="apartments-plan-img">
										<img src={floorPlanPic} alt="#" />
									</div>
								</div>
								{selectedUnit.node.description &&
									<div className="col-lg-5">
										<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
										<h2>{selectedUnit.node.unitType.type}</h2>
										<p>{selectedUnit.node.description}</p>
										</div>
									</div>
								}
								</div>
							</div>
							</div>
						</div>
						</div>
						{/* APARTMENTS PLAN AREA END */}
						{/* <h4 className="title-2">Property Video</h4>
						<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60" data-bs-bg={publicUrl+"assets/img/others/5.jpg"}>
						<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/eWUxqVFBq74?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
						</a>
						</div> */}
					</div>
					</div>
					<div className="col-lg-4">
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						{/* Form Widget */}
						<div className="widget ltn__form-widget">
							<Formik
								initialValues={{
									firstName: "",
									lastName: "",
									email: "",
									phoneNumber: "",
									unit: selectedUnit.node.number
								}}
								validationSchema={leadSchema}
								onSubmit={(values, {resetForm}) => {
									leadFormSubmit(values);
									resetForm();
								}}
							>
								{formik => (
									<div>
										<Form id="contact" className="contact-form-wrap contact-form-bg">
											<h4> Contact Us</h4>
											<TextField label="First Name" name="firstName" type="text" />
											<TextField label="Last Name" name="lastName" type="text" />
											<TextField label="Email" name="email" type="email" />
											<TextField label="Phone Number" name="phoneNumber" type="text" />
											<label style={{display: 'block', width: '240px', margin: '0 auto'}}>Are you at least 18 years of age?</label>
											<input
												onClick={()=>setAgeCheck(!ageCheck)}
												type="checkbox"
												style={{transform: 'scale(1.4, 1.4)', margin: '2px auto 0 auto', display: 'block', width: '17.23px'}}
											/>
											<div className="btn-wrap text-center">
											{loader ?
												<div style={{transform: 'scale(0.4)'}}>
													<Loader style={{margin: '0 auto'}}/>
												</div>
											:
												<button disabled={!ageCheck} style={{backgroundColor: "#E53E29", color: 'white'}} className="btn" type="click">Submit</button>
											}
											</div>
										</Form>
									</div>
								)}
							</Formik>
						</div>
						{/* <div id="contact" className="contact-form-wrap contact-form-bg"> */}
								<div className="btn-wrap text-center">
							<h4> Application</h4>
							<Link 
                      className='appointment'
                      to={`/application/${selectedUnit.node.id}/${location.name}/${selectedUnit.node.number}` }
                      state={{ selectedUnit }}
                      style={{backgroundColor: '#fda94f', color: 'white', borderRadius: '4px', marginTop: '10px', width: '295px'}}  type="click"
                    >
                      Apply Now
                    </Link>
								</div>
							{/* </div> */}
						{/* Social Media Widget */}
						<div className="widget ltn__social-media-widget">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
							<div className="ltn__social-media-2">
								{socials &&(socials.facebook || socials.twitter || socials.linkedIn || socials.instagram || socials.yelp) &&
									<ul className="social-icon">
										{socials.facebook &&      
											<li>
												<a href={`http://${socials.facebook}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-facebook" /></a>
											</li>
										}
										{socials.twitter &&      
											<li>
												<a href={`http://${socials.twitter}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-twitter" /></a>
											</li>
										}
										{socials.linkedIn &&      
											<li>
												<a href={`http://${socials.linkedIn}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-linkedin" /></a>
											</li>
										}
										{socials.instagram &&      
											<li>
												<a href={`http://${socials.instagram}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-instagram" /></a>
											</li>
										}
										{socials.yelp &&      
											<li>
												<a href={`http://${socials.yelp}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-yelp" /></a>
											</li>
										}
									</ul>
									}
							</div>
						</div>
					</aside>
					
					</div>
				</div>
			</div>
		</div>
	);
}

export default ShopDetails