import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';

function Page_header(props) {
	const { photosArray } = useContext(Context);
	const [headerPhoto, setHeaderPhoto] = useState(photosArray);

	useEffect(() => {
		if (photosArray.length >= 4) {
			setHeaderPhoto(props.from === 'units' ? photosArray[1] : (props.from === 'contact') ? photosArray[2] : photosArray[3]);
		} else if (photosArray.length === 3) {
			setHeaderPhoto(props.from === 'units' ? photosArray[1] : (props.from === 'contact') ? photosArray[2] : photosArray[0]);
		} else if (photosArray.length === 2) {
			setHeaderPhoto(props.from === 'details' ? photosArray[0] : photosArray[1]);
		} else {
			setHeaderPhoto(photosArray[0])
		}
  },[photosArray]);

	const inlineStyle = {
		backgroundImage: `url(${headerPhoto})`
	}
	
  return (
		<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} style={inlineStyle}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h1 className="page-title">{props.headertitle}</h1>
						<div style={{fontWeight: '600'}}>
							<Link to="/">Home</Link> {`>`} {props.headertitle}
						</div>
					</div>
				</div>
			</div>
		</div>
  );
}


export default Page_header