import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../AppContext';
import { Grid } from 'semantic-ui-react'

function Footer_v1 () {
  const { location } = useContext(Context);
  const { socials } = JSON.parse(location.contact);

  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  },[]);

  return (
    <footer className="footer-area style-two">
      <Grid container>
        <div className="footer-top">
          <Grid.Row centered>
            <Grid.Column>
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0,0);
                }}
                className="nav-text"
                style={{fontSize: "36px"}}
              >
                {location.customId}
              </Link>
            </Grid.Column>
            <Grid.Column className="footer-icons">
              <div className="footer-social text-sm-right">
                <Link 
                  to="/contact" 
                  onClick={()=> {
                    window.scrollTo(0,0);
                  }}
                  className="nav-text"
                  style={{padding: "10px"}}
                >
                  CONTACT US
                </Link>
                {socials && (socials.facebook || socials.twitter || socials.linkedIn || socials.instagram || socials.yelp) &&
                  <>
                    <span>FOLLOW US</span>
                    <ul className="social-icon">
                      {socials.facebook &&      
                        <li>
                          <a href={socials.facebook} target="_blank" rel='noopener noreferrer'><i className="fa fa-facebook" /></a>
                        </li>
                      }
                      {socials.twitter &&      
                        <li>
                          <a href={socials.twitter} target="_blank" rel='noopener noreferrer'><i className="fa fa-twitter" /></a>
                        </li>
                      }
                      {socials.linkedIn &&      
                        <li>
                          <a href={socials.linkedIn} target="_blank" rel='noopener noreferrer'><i className="fa fa-linkedin" /></a>
                        </li>
                      }
                      {socials.instagram &&      
                        <li>
                          <a href={socials.instagram} target="_blank" rel='noopener noreferrer'><i className="fa fa-instagram" /></a>
                        </li>
                      }
                      {socials.yelp &&      
                        <li>
                          <a href={socials.yelp} target="_blank" rel='noopener noreferrer'><i className="fa fa-yelp" /></a>
                        </li>
                      }
                    </ul>
                  </>
                }
              </div>  
            </Grid.Column>
          </Grid.Row>
        </div>
      </Grid>
    </footer>
  );
}

export default Footer_v1;