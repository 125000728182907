import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'


function Questions (props)  {
    const [disableMiddle, setDisableMiddle] = useState(false);
    const [Questions, setQuestions] = useState({
        consentEmergencyContact:"Yes",
        consentGroupedApplications:"Yes",
        consentEmergencyContactDesc:'',
        consentGroupedApplicationsDesc:''
    });

    useEffect(() => {
      if (performance.navigation.type === 1) {
          const Questions = localStorage.getItem('Questions')
          Questions &&setQuestions(JSON.parse(Questions))
      } else {
        const Questions = localStorage.getItem('Questions')
        Questions &&setQuestions(JSON.parse(Questions))
      }
  }, []);
  // Effect handler to set local storage
  useEffect(() => {
      const itemToSave = JSON.stringify(Questions)
      if (Object.keys(itemToSave).length !== 0) {
          localStorage.setItem('Questions', itemToSave)
      }
  }, [Questions]);

    const Stepper = () => {
      props.setDataObject(prevState => ({ ...prevState, Page7: Questions}))
      props.setAppStep(8)
  }

  return (
    <>
    <div>
    <h1 class="section__heading">Questions</h1>

      <Form>
            <h4> UNDERSTAND THAT MY APPLICATION WILL NOT BE REVIEWED WITHOUT EMERGENCY CONTACTS LISTED.</h4>
            <Checkbox name="noMiddle" label='Yes' checked={Questions&&Questions.consentEmergencyContact =="Yes"} value='Yes' onChange={(e,data) => setQuestions(prev => ({...prev, consentEmergencyContact:data.value}))}/>
            <Checkbox name="noMiddle" label='No' checked={Questions&&Questions.consentEmergencyContact =="No"} value="No" onChange={(e,data) => setQuestions(prev => ({...prev, consentEmergencyContact:data.value}))}/>
          <Form.Field>
              <label>Explain</label>
              <input name="explainFirstQuestion" value={Questions&&Questions.consentEmergencyContactDesc} onChange={(e,data) => setQuestions(prev => ({...prev, consentEmergencyContactDesc:e.target.value}))} />
          </Form.Field>
          <h4> I UNDERSTAND that IF I am applying with other occupants/roommates, ALL applications must be submitted before our grouped application is considered COMPLETE for the screening order.</h4>
          <Checkbox name="noMiddle" label='Yes' checked={Questions&&Questions.consentGroupedApplications =="Yes"} value='Yes' onChange={(e,data) => setQuestions(prev => ({...prev, consentGroupedApplications:data.value}))}/>
            <Checkbox name="noMiddle" label='No' checked={Questions&&Questions.consentGroupedApplications =="No"} value="No" onChange={(e,data) => setQuestions(prev => ({...prev, consentGroupedApplications:data.value}))}/>
          <Form.Field >
              <label>Explain</label>
              <input name="explainSecondQuestion" value={Questions&&Questions.consentGroupedApplicationsDesc} onChange={(e,data) => setQuestions(prev => ({...prev, consentGroupedApplicationsDesc:e.target.value}))} />
          </Form.Field>
      </Form>
      <br></br>
      <div style={{float:'right'}}>
          <Button icon labelPosition='left' onClick={() => props.setAppStep(6)}>
          <Icon name='left arrow' />
          Previous
          </Button>
          <Button icon labelPosition='right' onClick={() => Stepper()}>
          Save & Next
          <Icon name='right arrow' />
          </Button>
      </div>

    </div>
  </>
  );
}

export default Questions