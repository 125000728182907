import {
  ApolloClient, InMemoryCache, HttpLink,
} from 'apollo-boost';
import config from './config';

export const getLink = (uri) => new HttpLink({ uri: uri || config.GRAPHQL_URL });


export const getClient = (uri) => new ApolloClient({
  link: getLink(uri), // Chain it with the HttpLink
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
