import React from 'react';
import { Loader }  from 'semantic-ui-react';
import './PageLoader.scss';

function PageLoader() {
  return (
    <div className="container-loader">
      <Loader className="semantic-loader" size="massive" active inline='centered'>Loading...</Loader>
      {/* <p className="loader-p">Loading...</p> */}
    </div>
  );
}

export default PageLoader;