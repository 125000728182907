import React, { useContext } from 'react';
import { Context } from '../../../../../AppContext';
import MapContainer from '../../../../../shared_components/google-map';

function Map() {
    const { location } = useContext(Context);

    return (
        <div className="google-map mt-140">
			<MapContainer lat={location.lat} lng={location.lng} name={location.name}/>
		</div>
    );
}

export default Map