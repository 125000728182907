import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-v2';
import ApartmentV2 from './section-components/apartment-v2';
import Availability from './section-components/availability';
import Category from './section-components/category-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Home_V11 = () => {
    return <div>
        <Navbar/> 
        <Banner />
        <ApartmentV2 customClass="pt-90"/>
        <Availability />
        <Category />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V11

