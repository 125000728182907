import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';

function Page_header(props) {
	const { photosArray } = useContext(Context);
	const [headerPhoto, setHeaderPhoto] = useState(photosArray);
	const HeaderTitle = props.headertitle;
	const Subheader = props.subheader ? props.subheader : HeaderTitle;

	useEffect(() => {
		if (photosArray.length >= 4) {
			setHeaderPhoto(props.from === 'units' ? photosArray[1] : (props.from === 'contact') ? photosArray[2] : photosArray[3]);
		} else if (photosArray.length === 3) {
			setHeaderPhoto(props.from === 'units' ? photosArray[1] : (props.from === 'contact') ? photosArray[2] : photosArray[0]);
		} else if (photosArray.length === 2) {
			setHeaderPhoto(props.from === 'details' ? photosArray[0] : photosArray[1]);
		} else {
			setHeaderPhoto(photosArray[0])
		}
  },[photosArray]);

	const inlineStyle = {
		backgroundImage: `url(${headerPhoto})`
}

  return (
		<div className="breadcrumb-area bg-overlay-2" style={ inlineStyle }>
		  <div className="container">
		    <div className="breadcrumb-inner">
		      <div className="section-title text-center">
		        <h2 className="page-title">{ HeaderTitle }</h2>
		        <ul className="page-list">
		          <li className='contact-link'>
								<Link
									className='contact-icon'
									to="/"
									onClick={()=> {
                    window.scrollTo(0,0);
                  }}
								>
									Home
								</Link>
							</li>
		          <li>{ Subheader }</li>
		        </ul>
		      </div>
		    </div>
		  </div>
		</div>
	);
}


export default Page_header