import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';
import locIcon from '../../assets/img/icon/location-alt.png';
import locIcon2 from '../../assets/img/icon/location2.png';
import placeholder from '../../../../../assets/undraw/undraw_apartment.png';

function Property() {
	const { location, updatedUnits } = useContext(Context);
	const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
	const address = JSON.parse(location.addresses);

	const [properties, setProperties] = useState(updatedUnits.sort((a, b) => a.node.sqft - b.node.sqft));

	useEffect(() => {
    const vacant = [];
    const notVacant = [];
    properties.forEach((p, i) => {
      if(p.node.status === "vacant") {
        vacant.push(p);
      } else {
        notVacant.push(p)
      }
    })
    setProperties(vacant.concat(notVacant));
  }, [])
	const filteredUnits = properties.length <= 6 ? properties : properties.slice(0, 6);

	const handleLink = (k) => {
		localStorage.setItem("selectedUnit", JSON.stringify(k));
		window.scrollTo(0,0);
	}

	return (
		orgDetails !== undefined &&
		<div className="propartes-area pd-top-118 pd-bottom-90 ">
			<div className="container">
				<div className="section-title text-center">
					<h2>Our Properties </h2>
				</div>
				<div className="tab-content go-top" id="myTabContent">
					<div className="tab-pane fade show active" id="rent1" role="tabpanel" aria-labelledby="rent1-tab">
						<div className="row go-top">
							{filteredUnits.map((k,i) => {
								return (
									<div className="col-lg-4 col-md-6">
										<div className="single-product-wrap style-bottom">
											<div className="thumb">
												<img src={k.node.photos[0] ? k.node.photos[0] : placeholder} style={{height: '264.46px', width: '396.7px'}} alt="img" />
												<div className="product-wrap-details">
													<div className="media">
														<div className="media-body">
															<h6>
																<Link
																	to="/property-grid"
																	onClick={() => window.scrollTo(0,0)}
																	>
																		Company
																	</Link>
																</h6>
															<p><img src={locIcon} alt="img" />{orgDetails.name}</p>
														</div>
														<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
													</div>
												</div> 
											</div> 
											<div className="product-details-inner">
												<h4>
													<Link
														to="/property-details"
														onClick={()=> {
															handleLink(k);
														}}
													>
														{k.node.unitType.type}
													</Link>
												</h4>
												<ul className="meta-inner">
													<li><img src={locIcon2} alt="img" />{address[0].city}</li>
													<li>
														<Link
															to="/property-details"
															onClick={()=> {
																handleLink(k);
															}}
														>
															{k.node.status.slice(0,1).toUpperCase()}{k.node.status.slice(1, k.node.status.length)}
														</Link>
													</li>
												</ul>
												<p>{k.node.description}</p>
											</div>
											<div className="product-meta-bottom">
												<span className="price">${k.node.price}</span>
												<span>{k.node.status}</span>
											</div>         
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Property;