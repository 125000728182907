import React, { useContext, useState } from 'react';
import { Icon, Step, Grid, Segment, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'


function PostSubmit() {


  return (
    <>
      <div style={{marginTop:'25%'}}>
        <Header  as='h2' icon textAlign='center'>
          <Icon color='teal' circular inverted name='home' />
          Application Submitted!
          <Header.Subheader textAlign='center' style={{marginLeft:'200px', marginRight:'200px', overflowWrap:'anywhere'}}>
          <br></br>
            Please check your email for any further communication. Your property manager should be reaching out to you shortly after their review. If you have not heard from them in a few days then contact the property directly.
          </Header.Subheader>
        </Header>
      </div>

    </>
  );
}

export default PostSubmit