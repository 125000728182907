import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Label, List, Button, Header, Segment, Divider, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'
import moment from "moment";

function Review(props) {
  const [data, setData] = useState(props.dataObject);
  useEffect(() => {
    setData(props.dataObject)
    console.log(props)
  }, [props]);

  const ContactInfo = () => {
    const contact = data.Page2
    const move = data.Page1
    if (contact) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Contact Info</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(2)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            <Header textAlign="center" as="h3">Information</Header>
            <Grid columns="3">
              <Grid.Column textAlign="center">
                <Header as="h4">
                  First Name
                  <Header.Subheader>{contact.firstName?contact.firstName:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Suffix
                  <Header.Subheader>{contact.title?contact.title:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Phone
                  <Header.Subheader>{contact.phoneMobile && contact.phoneMobile.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Last Name
                  <Header.Subheader>{contact.lastName?contact.lastName:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Email
                  <Header.Subheader>{contact.email?contact.email:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Move In
                  <Header.Subheader>{move?moment(move).format("MM/DD/YYYY"):" "}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Middle Name
                  <Header.Subheader>{contact.middleName?contact.middleName:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Source
                  <Header.Subheader style={{ textTransform: 'capitalize' }}>{contact.source?contact.source:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Segment>
        </>
      )
    }
  }
  const WhereYouLived = () => {
    const lived = data.Page3
    if (lived) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Residence</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(3)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            {
              lived.rentalHistory && lived.rentalHistory.map(location => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Location</Header>
                    <Grid columns="3">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Address
                          <Header.Subheader>{location.streetLine1?location.streetLine1:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          State
                          <Header.Subheader>{location.state?location.state:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Landlord Name
                          <Header.Subheader>{location.landlordName?location.landlordName:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Monthly Rent
                          <Header.Subheader>{location.monthlyRent?location.monthlyRent:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Address 2
                          <Header.Subheader>{location.streetLine2?location.streetLine2:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Country
                          <Header.Subheader>{location.country?location.country:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Landlord Email
                          <Header.Subheader>{location.landlordEmail?location.landlordEmail:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Month
                          <Header.Subheader>{location.month?location.month:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          City
                          <Header.Subheader>{location.city?location.city:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Zip
                          <Header.Subheader>{location.postcode?location.postcode:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Landlord Phone
                          <Header.Subheader>{location.landlordPhone?location.landlordPhone:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Year
                          <Header.Subheader>{location.year?location.year:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
            {
              lived.references && lived.references.map(ref => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Reference</Header>
                    <Grid columns="3">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Name
                          <Header.Subheader>{ref.name?ref.name:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Address
                          <Header.Subheader>{ref.address?ref.address:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Relation
                          <Header.Subheader>{ref.relationship?ref.relationship:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Phone
                          <Header.Subheader>{ref.mobilePhone?ref.mobilePhone:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
          </Segment>
        </>
      )
    }
  }
  const Occupants = () => {
    const occupants = data.Page4
    if (occupants) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Applicants</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(4)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            {
              occupants.CoApplicant && occupants.CoApplicant.map(co => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Co Applicant</Header>
                    <Grid columns="3">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          First Name
                          <Header.Subheader>{co.firstName?co.firstName:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Email
                          <Header.Subheader>{co.email?co.email:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Last Name
                          <Header.Subheader>{co.lastName?co.lastName:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Phone
                          <Header.Subheader>{co.phoneMobile && co.phoneMobile.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
            {
              occupants.Occupants && occupants.Occupants.map(oc => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Occupant</Header>
                    <Grid columns="2">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          First Name
                          <Header.Subheader>{oc.firstName?oc.firstName:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Last Name
                          <Header.Subheader>{oc.lastName?oc.lastName:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
            {
              occupants.Pets && occupants.Pets.map(pet => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Pet</Header>
                    <Grid columns="3">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Name
                          <Header.Subheader>{pet.name?pet.name:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Weight
                          <Header.Subheader>{pet.weight?pet.weight:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Breed
                          <Header.Subheader>{pet.breed?pet.breed:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Age
                          <Header.Subheader>{pet.age?pet.age:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
          </Segment>
        </>
      )
    }
  }
  const PersonalInfo = () => {
    const personal = data.Page5
    if (personal) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Personal</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(5)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            <Header textAlign="center" as="h3">Emergency Contact</Header>
            <Grid columns="3">
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Name
                  <Header.Subheader>{personal.emergencyName?personal.emergencyName:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Address
                  <Header.Subheader>{personal.Address?personal.Address:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Relation
                  <Header.Subheader>{personal.Relationship?personal.Relationship:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Phone
                  <Header.Subheader>{personal.emergencyPhone && personal.emergencyPhone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
            <Divider />
            <Header textAlign="center" as="h3">Licensing</Header>
            <Grid columns="3">
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Drivers License
                  <Header.Subheader>{personal.driversLic?personal.driversLic:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Date of Birth
                  <Header.Subheader>{moment(personal.DOB).format('MM/DD/YYYY')}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  State
                  <Header.Subheader>{personal.licenseState?personal.licenseState:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  SSN
                  <Header.Subheader>{personal.idNum !== "" && "*********"}</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
            <Divider />
            {
              personal.Car && personal.Car.map(car => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Vehicle</Header>
                    <Grid columns="3">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Make
                          <Header.Subheader>{car.make?car.make:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          Year
                          <Header.Subheader>{car.year?car.year:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Model
                          <Header.Subheader>{car.model?car.model:" "}</Header.Subheader>
                        </Header>
                        <Header as="h4">
                          License
                          <Header.Subheader>{car.license?car.license:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Color
                          <Header.Subheader>{car.color?car.color:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
          </Segment>
        </>
      )
    }
  }
  const Income = () => {
    const income = data.Page6
    if (income) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Income</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(6)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            <Header textAlign="center" as="h3">Employer</Header>
            <Grid columns="3">
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Name
                  <Header.Subheader>{income.employerName?income.employerName:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  City
                  <Header.Subheader>{income.employerCity?income.employerCity:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Position
                  <Header.Subheader>{income.employerPosition?income.employerPosition:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Supervisor Name
                  <Header.Subheader>{income.employerSupervisorName?income.employerSupervisorName:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Address
                  <Header.Subheader>{income.employerAddress1?income.employerAddress1:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Country
                  <Header.Subheader>{income.employerCountry?income.employerCountry:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Monthly Salary
                  <Header.Subheader>$ {income.employerSalary?income.employerSalary:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Supervisor Email
                  <Header.Subheader>{income.employerSupervisorEmail?income.employerSupervisorEmail:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">
                  Unit
                  <Header.Subheader>{income.employerAddress2?income.employerAddress2:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Zip
                  <Header.Subheader>{income.employerZip?income.employerZip:" "}</Header.Subheader>
                </Header>
                <Header as="h4">
                  Years Worked
                  <Header.Subheader>{income.employerWorked?income.employerWorked:" "}</Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
            <Divider />
            {
              income.Additional && income.Additional.map(add => {
                return (
                  <>
                    <Header textAlign="center" as="h3">Additional</Header>
                    <Grid columns="2">
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Name
                          <Header.Subheader>{add.name?add.name:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        <Header as="h4">
                          Amount
                          <Header.Subheader>$ {add.amount?add.amount:" "}</Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Divider />
                  </>
                )
              })
            }
          </Segment>
        </>
      )
    }
  }
  const Questions = () => {
    const questions = data.Page7
    if (questions) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Questions</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(7)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            <>
              <Header textAlign="center" as="h3">Emergency Consent</Header>
              <Grid columns="2">
                <Grid.Column textAlign="center">
                  <Header as="h4">
                    Answer
                    <Header.Subheader>{questions.consentEmergencyContact?questions.consentEmergencyContact:" "}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header as="h4">
                    Reason
                    <Header.Subheader>{questions.consentEmergencyContactDesc?questions.consentEmergencyContactDesc:" "}</Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
              <Divider />
              <Header textAlign="center" as="h3">Occupant Consent</Header>
              <Grid columns="2">
                <Grid.Column textAlign="center">
                  <Header as="h4">
                    Answer
                    <Header.Subheader>{questions.consentGroupedApplications?questions.consentGroupedApplications:" "}</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header as="h4">
                    Reason
                    <Header.Subheader>{questions.consentGroupedApplicationsDesc?questions.consentGroupedApplicationsDesc:" "}</Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
              <Divider />
            </>
          </Segment>
        </>
      )
    }
  }
  const Documents = () => {
    const documents = data.Page8
    if (documents) {


      return (
        <>
          <Message color='purple'>
            <Grid columns="2">
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Header style={{ color: 'black' }} as="h3">Documents</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" style={{ padding: '0px' }}>
                <Icon link onClick={() => props.setAppStep(8)} color="black" name="edit"></Icon>
              </Grid.Column>
            </Grid>
          </Message>
          <Segment>
            <Grid columns="3">
              <Grid.Column textAlign="center">
                <Header as="h5">ID</Header>
                <Icon.Group size='huge'>
                  <Icon style={{ border: '0px' }} name='id card outline' />
                  {
                    documents.Id == null ?
                      <Icon corner color="red" name='x' />
                      :
                      <Icon corner color="green" name='check' />
                  }
                </Icon.Group>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h5">Income</Header>
                <Icon.Group size='huge'>
                  <Icon name='dollar sign' />
                  {
                    documents.Proof == null ?
                      <Icon corner color="red" name='x' />
                      :
                      <Icon corner color="green" name='check' />
                  }
                </Icon.Group>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h5">Other</Header>
                <Icon.Group size='huge'>
                  <Icon name='file text outline' />
                  {
                    documents.Other == null ?
                      <Icon corner color="red" name='x' />
                      :
                      <Icon corner color="green" name='check' />
                  }
                </Icon.Group>
              </Grid.Column>
            </Grid>
          </Segment>
        </>
      )
    }
  }
  return (
    <>
      {ContactInfo()}
      {WhereYouLived()}
      {Occupants()}
      {PersonalInfo()}
      {Income()}
      {Questions()}
      {Documents()}
      <Button floated="right" loading={props.loader} disabled={props.loader} onClick={() => props.createApplication()}>Submit</Button>
    </>
  );
}

export default Review