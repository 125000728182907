import React, { Component } from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV2 from './components/home-v2';
import PropertyDetails from './components/property-details';
import PropertyGrid from './components/property-grid';
import Contact from './components/contact';
import './assets/css/responsive.css';
import './assets/css/style.css';
import TourPopup from '../../../shared_components/TourPopup';
import HomeApplication from '../../../shared_components/Application/index.js';


function Microsite_2(props) {
    const PrivateRoute = ({ component: Component, isServiceProf, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(props) =>
                    <Component {...props}  {...rest} />
                }
            />
        );
    };
    return (
        <Router>
            <HashRouter basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV2} />
                        <Route path="/property-details" component={PropertyDetails} />
                        <Route path="/property-grid" component={PropertyGrid} />
                        <Route path="/contact" component={Contact} />
                        <PrivateRoute path="/application/:id/:name/:unit" local={props} component={HomeApplication} />
                    </Switch>
                </div>
            </HashRouter>
            <TourPopup background="#fda94f" submitStyle={{ backgroundColor: '#fda94f', color: 'white', borderRadius: '3px' }} />
        </Router>
    )
}



// class Microsite_2 extends Component {
//     render(props) {
//         return(
//             <>
//                 <HashRouter basename="/">
// 	                <Switch>
//                         <Route exact path="/" component={HomeV2} />
//                         <Route  path="/property-details" component={PropertyDetails} />
//                         <Route  path="/property-grid" component={PropertyGrid} />
//                         <Route  path="/contact" component={Contact} />
//                         <Route path="/application/:id/:name/:unit"props={props} component={HomeApplication} />
// 	                </Switch>
//                 </HashRouter>
//                 <TourPopup background="#051539" submitStyle={{backgroundColor: '#051539', color: 'white', borderRadius: '3px'}}/>
//             </>

//         )
//     }
// }

export default Microsite_2;
