import React from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV4 from './components/home-v4';
import Property from './components/property';
import PropertyDetails from './components/property-details';
import Error from './components/error';
import TourPopup from '../../shared_components/TourPopup';
import HomeApplication from '../../shared_components/Application/index.js';
import Contact from './components/contact-us';

function Microsite_1(props) {

    const PrivateRoute = ({ component: Component, isServiceProf, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(props) =>
                    <Component {...props}  {...rest} />
                }
            />
        );
    };

    return (
        <Router>
            <HashRouter basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV4} />
                        <Route path="/units" component={Property} />
                        <Route
                            path="/unit-details"
                            component={PropertyDetails}
                        />
                        <Route path="/error" component={Error} />
                        <Route path="/contact" component={Contact} />
                        <PrivateRoute path="/application/:id/:name/:unit" local={props} component={HomeApplication} />
                    </Switch>
                </div>
            </HashRouter>
            <TourPopup background="#fda94f" submitStyle={{ backgroundColor: '#fda94f', color: 'white', borderRadius: '3px' }} />
        </Router>
    )
}

export default Microsite_1;