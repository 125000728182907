import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button, Segment, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'

function Documents(props) {
  const [disableMiddle, setDisableMiddle] = useState(false);
  const [Document, setDocument] = useState({
    Id:null,
    Proof:null,
    Other:null
  });
  useEffect(() => {
    if (disableMiddle == true) {
      props.setDataObject(prevState => ({
        ...prevState,
        ["middleName"]: ''
      }));

    }
  }, [disableMiddle]);

  const Stepper = () => {
    props.setDataObject(prevState => ({ ...prevState, Page8: Document}))
    props.setAppStep(9)
}

   const fileUploadId = (e) => {
    const value = e.target.files[0]
    setDocument(prevState => ({
      ...prevState,
      Id: value
    }));
  };
  const fileUploadProof = (e) => {
    const value = e.target.files[0]
    setDocument(prevState => ({
      ...prevState,
      Proof: value
    }));
  };
  const fileUploadOther = (e) => {
    const value = e.target.files[0]
    console.log(value)
    setDocument(prevState => ({
      ...prevState,
      Other: value
    }));
  };

  const getId = () => {
    document.getElementById('selectedFile').click();
  }
  const getProof = () => {
    document.getElementById('selectedFileP').click();
  }
  const getOther = () => {
    document.getElementById('selectedFileO').click();
  }


  return (
    <>
      <div>
        <h1 class="section__heading">Attach documents</h1>
        <p id="attach_documents_instruction_text">
          Please upload all required documentation including:<br />
          <br />
          - Photo ID<br />
          - Proof of income or alternative income (e.g. recent pay stubs, bank statements, or tax returns, child support, pension, TANF, housing vouchers or subsidies)<br />

        </p>
        <div>
          <label>Photo ID</label>
          <Segment color={Document.Id !== null && "green"}>
            <input type="file" id="selectedFile" style={{ display: 'none' }} onChange={(e) => fileUploadId(e)} />
            <input type="button" value="Browse..." onClick={() => getId()} />
            {Document.Id !== null && <Icon style={{ float: 'right' }} color='green' name='check' />}
          </Segment>
        </div>
        <Divider />
        <div>

          <label>Proof of Income</label>
          <Segment color={Document.Proof !== null && "green"}>
            <input type="file" id="selectedFileP" style={{ display: 'none' }} onChange={(e) => fileUploadProof(e)} />
            <input type="button" value="Browse..." onClick={() => getProof()} />
            {Document.Proof !== null && <Icon style={{ float: 'right' }} color='green' name='check' />}
          </Segment>
        </div>
        <Divider />
        <div>
          <label>Other</label>
          <Segment color={Document.Other !== null && "green"}>
            <input type="file" id="selectedFileO" style={{ display: 'none' }} onChange={(e) => fileUploadOther(e)} />
            <input type="button" value="Browse..." onClick={() => getOther()} />
            {Document.Other !== null && <Icon style={{ float: 'right' }} color='green' name='check' />}
          </Segment>
        </div>
        <br></br>
        <div style={{ float: 'right' }}>
          <Button icon labelPosition='left' onClick={() => props.setAppStep(7)}>
            <Icon name='left arrow' />
            Previous
          </Button>
          <Button icon labelPosition='right' onClick={() => Stepper()}>
            Save & Next
            <Icon name='right arrow' />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Documents