import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../AppContext';
import loc2 from '../../assets/img/icon/location2.png';
import placeHolderPlanPic from '../../../../../assets/undraw/undraw_plan.png';
import placeholderUnitPic from '../../../../../assets/undraw/undraw_apartment.png';
import MapContainer from '../../../../../shared_components/google-map';
import { Formik, Form } from 'formik';
import { TextField } from '../../../../../shared_components/TextField';
import { leadSchema } from '../../../../../Validations/UserValidations';
import { ToastContainer, toast } from 'react-toastify';
import { getClient } from '../../../../../init-apollo-googleFn';
import { MICROSITE_INFO } from '../../../../../utils/constants';
import { createLeadGql } from '../../../../../store/schemas/location';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Carousel from '../../../../../shared_components/Carousel';
import '../../style.scss';
import { Icon } from 'semantic-ui-react';


function PropertyDetails(props) {
	const { location, locationId, locationAmenities } = useContext(Context);
	const address = JSON.parse(location.addresses)[0];
	const selectedUnit = JSON.parse(localStorage.getItem('selectedUnit'));
	const photos = selectedUnit&&selectedUnit.node&&selectedUnit.node.photos;
	const floorPlanPic = selectedUnit.floorPlan && (selectedUnit.floorPlan.node.photos !== "[]" && selectedUnit.floorPlan.node.photos !== "\"\"") ? selectedUnit.floorPlan.node.photos.slice(4, selectedUnit.floorPlan.node.photos.length -4) : placeHolderPlanPic;
	const [toastMessage, setToastMessage] = useState(null);
	const [ageCheck, setAgeCheck] = useState(false);
	const [loader, setLoader] = useState(false);



	const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
			setLoader(true);
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: selectedUnit.node.id,
            }
          }
        }
      }).then(response => {
				setLoader(false);
				if (response.data.createLead.response === null && response.data.createLead.lead.id) {
					setToastMessage(toast.success("We've received your contact info. An agent will email you soon."));
				} else if (response.data.createLead.response !== null && response.data.createLead.lead.id) {
					setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
					setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e => console.log(e));
    }
    catch(e) {
			setLoader(false);
      console.log(e);
    }
  }

	  /* eslint-disable */
		useEffect(() => {
			return toastMessage;
		}, [toastMessage]);
	
		const leadFormSubmit = async(values) => {
			const isValid = await leadSchema.isValid();
			createLead(locationId, values);
		}

		useEffect(() => {
			const $ = window.$;
			console.log(selectedUnit)
			console.log(location)
			$( 'body' ).addClass( 'body-bg' );
		},[])
		/* eslint-enable */

  return (
		<div className="property-page-area pd-top-120 pd-bottom-90 ">
			<ToastContainer position="bottom-center"/>
			<div className="container">
				<div className="property-details-top pd-bottom-70">
					<div className="property-details-top-inner">
						<div className="row">
							<div className="col-lg-7">
								<h3>{location.name}: {selectedUnit.node.unitType.type}</h3>
								<p><img src={loc2} alt="img" /> {address.streetOne}, {address.city}, {address.state}, {address.country}, {address.zip}</p>
							</div>
							<div className="col-lg-5 text-lg-right">
								<h4>$ {selectedUnit.node.price}</h4>
								<ul style={{marginTop: '18px'}}>
									<li>{selectedUnit.node.bedrooms} Bedroom</li>
									<li>{selectedUnit.node.bathrooms} Bathroom</li>
									<li>{selectedUnit.node.sqft} sqft</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="product-thumbnail-wrapper micro-2-details">
						<div className="single-thumbnail-slider">
							{photos.length > 1 ?
								<Carousel photos={photos}/>
              :
								<div className="item">
									<div className="thumb">
										<img
											style={{width: '100%'}}
											src={photos.length === 1 ? photos[0] : placeholderUnitPic}
											alt="apartment"/>
									</div>
								</div>
              }
						</div>
					</div>
				</div>
				<div className="row go-top">
					<div className="col-lg-8">
						<div className="single-property-details-inner">
							<h4>{selectedUnit.node.unitType.type}</h4>
							<p>{selectedUnit.node.description}</p>
							<div className="single-property-grid">
								<h4>Property Details</h4>
								<div className="row">
									<div className="col-md-4">
										<ul>
											<li>Price: {selectedUnit.node.price}</li>
											<li>Floor: {selectedUnit.node.level}</li>
										</ul>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Size: {selectedUnit.node.sqft} sqft</li>
											<li>Bedrooms: {selectedUnit.node.bedrooms}</li>
										</ul>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Unit: {selectedUnit.node.number}</li>
											<li>Bathrooms: {selectedUnit.node.bathrooms}</li>
										</ul>
									</div>
								</div>
							</div>
							{selectedUnit.amenities ?
								<div className="single-property-grid">
										<h4>Amenities</h4>
										<div className="row">
											{
												selectedUnit.amenities.map((amenity, i) => 
													<div key={i} className="col-sm-4 mb-2">
														<ul>
															<li><i className="fa fa-check margin-top-10" />{amenity}</li>
														</ul>
													</div>
											)}
										</div>
								</div>
							: (locationAmenities.length > 0) && 
								<div className="single-property-grid">
									<h4>Amenities</h4>
									<div className="row">
										{
											locationAmenities.map((amenity, i) => 
												<div key={i} className="col-sm-4 mb-2">
													<ul>
														<li><i className="fa fa-check margin-top-10" />{amenity}</li>
													</ul>
												</div>
										)}
									</div>
								</div>
              }
							<div className="single-property-grid details-map">
								<h4>Location</h4>
								<div className="property-map">
									<MapContainer lat={location.lat} lng={location.lng} name={location.name} inlineStyle={{height: '460px', width: '630px'}}/>
								</div>
							</div>
							<div className="single-property-grid">
								<h4>Floor Plan</h4>
								<img style={{height: '100%', width: '100%'}} src={floorPlanPic} alt="img" />
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<aside className="sidebar-area">
							<Formik
								initialValues={{
									firstName: "",
									lastName: "",
									email: "",
									phoneNumber: "",
									unit: selectedUnit.node.number
								}}
								validationSchema={leadSchema}
								onSubmit={(values, {resetForm}) => {
									leadFormSubmit(values);
									resetForm();
								}}
							>
								{formik => (
									<div>
										<Form id="contact" className="contact-form-wrap contact-form-bg">
											<h4> Contact Us</h4>
											<TextField label="First Name" name="firstName" type="text" />
											<TextField label="Last Name" name="lastName" type="text" />
											<TextField label="Email" name="email" type="email" />
											<TextField label="Phone Number" name="phoneNumber" type="text" />
											<label style={{display: 'block', width: '240px', margin: '0 auto'}}>Are you at least 18 years of age?</label>
											<input
												onClick={()=>setAgeCheck(!ageCheck)}
												type="checkbox"
												style={{transform: 'scale(1.4, 1.4)', margin: '2px auto 0 auto', display: 'block', width: '17.23px'}}
											/>
											<div className="btn-wrap text-center">
											{loader ?
												<div style={{transform: 'scale(0.4)'}}>
													<Loader style={{margin: '0 auto'}}/>
												</div>
											:
												<button disabled={!ageCheck} style={{backgroundColor: "rgb(5,21,80)", color: 'white', borderRadius: '3px'}} className="btn" type="click">Submit</button>
											}
											</div>
										</Form>
									</div>
								)}
							</Formik>
						</aside>
						<br></br>
						<aside className="sidebar-area">
							<div id="contact" className="contact-form-wrap contact-form-bg">
								<div className="btn-wrap text-center">
							<h4> Application</h4>
							<Link 
								className='appointment'
								to={`/application/${selectedUnit.node.id}/${location.name}/${selectedUnit.node.number}` }
								state={{ selectedUnit }}
								style={{backgroundColor: '#fda94f', color: 'white', borderRadius: '4px', marginTop: '10px', width: '295px'}}  type="click"
								>
								Apply Now
								</Link>
								</div>
							</div>
						</aside>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PropertyDetails

