import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../AppContext';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer-v2';
import MapContainer from '../../../shared_components/google-map';
import { leadSchema } from '../../../Validations/UserValidations';
import { MICROSITE_INFO } from '../../../utils/constants';
import { createLeadGql } from '../../../store/schemas/location';
import { getClient } from '../../../init-apollo-googleFn';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { TextField } from '../../../shared_components/TextField';
import { UnitDropDown } from '../../../shared_components/UnitDropDown';
import PageLoader from '../../../PageLoader';
import { Loader } from 'semantic-ui-react';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
	const { location, updatedUnits, locationId } = useContext(Context);
	const contact = JSON.parse(location.contact);
	const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
	const address = JSON.parse(location.addresses)[0];
	const [ageCheck, setAgeCheck] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [toastMessage, setToastMessage] = useState(null);
	const [loader, setLoader] = useState(false);

	/* eslint-disable */
	useEffect(() => {
		orgDetails.address !== undefined && setIsLoading(false);
	}, [orgDetails]);
	/* eslint-enable */

	const orderedUnits = [...updatedUnits].sort((a, b) => a.node.number - b.node.number);

	const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
			setLoader(true);
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: obj.unit,
            }
          }
        }
      }).then(response => {
				setLoader(false);
				if (response.data.createLead.response === "200" && response.data.createLead.lead.id) {
					setToastMessage(toast.success("We've received your contact info. An agent will email you soon."));
				} else if (response.data.createLead.response !== "200" && response.data.createLead.lead.id) {
					setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
					setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e =>  console.log(e));
    }
    catch(e) {
			setLoader(false);
      console.log(e);
    }
  }


	/* eslint-disable */
	useEffect(() => {
		return toastMessage;
	}, [toastMessage]);

  const leadFormSubmit = async(values) => {
    const isValid = await leadSchema.isValid();
		createLead(locationId, values);
  }
	/* eslint-enable */

	return (
		<>
			{
				isLoading
				?
				<PageLoader />
				:
				<>
				<Navbar />
				<PageHeader header="Contact" from="contact" subheader="Contact"/>
				<div className="contact-area pd-top-100">
					<ToastContainer position="bottom-center"/>
					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<div className="contact-page-map">
									<MapContainer lat={location.lat} lng={location.lng} name={location.name} inlineStyles={{height: '100%', width: '100%'}}/>
								</div>
							</div>
							<div className="col-lg-4">
							<Formik
								initialValues={{
									firstName: "",
									lastName: "",
									email: "",
									phoneNumber: "",
									unit: ""
								}}
								validationSchema={leadSchema}
								onSubmit={(values, {resetForm}) => {
									leadFormSubmit(values);
									resetForm();
								}}
							>
								{formik => (
									<div>
										<Form id="contact" className="contact-form-wrap contact-form-bg">
											<h4> Contact Us</h4>
											<TextField label="First Name" name="firstName" type="text" />
											<TextField label="Last Name" name="lastName" type="text" />
											<TextField label="Email" name="email" type="email" />
											<TextField label="Phone Number" name="phoneNumber" type="text" />
											<UnitDropDown label="Unit" name="unit" orderedUnits={orderedUnits} />
											<label style={{display: 'block', width: '240px', margin: '0 auto'}}>Are you at least 18 years of age?</label>
											<input
												onClick={()=>setAgeCheck(!ageCheck)}
												type="checkbox"
												style={{transform: 'scale(1.4, 1.4)', margin: '2px auto 0 auto', display: 'block', width: '17.23px'}}
											/>
											<div className="btn-wrap text-center">
												{loader ?
													<div style={{transform: 'scale(0.4)'}}>
														<Loader style={{margin: '0 auto'}}/>
													</div>
												:
													<button className="btn btn-yellow" style={{backgroundColor: '#fda94f', color: 'white'}} disabled={!ageCheck} type="click">Submit</button>
												}
											</div>
										</Form>
									</div>
								)}
							</Formik>
							</div>
						</div>
						<div className="row pd-top-92">
							<div className="col-xl-4 col-sm-6">
								<div className="single-contact-info">
									<p><i className="fa fa-phone" />Call Us:</p>
									<h5><a href={`tel:${contact.phone}`}>{`+1 (${contact.phone.slice(0, 3)}) ${contact.phone.slice(3, 6)} ${contact.phone.slice(6)}`}</a></h5>
								</div>
							</div>
							<div className="col-xl-4 col-sm-6">
								<div className="single-contact-info">
									<p><i className="fa fa-envelope" />Have any Question?</p>
									<h5><a href={`mailto:${contact.email}`}>{contact.email}</a></h5>
								</div>
							</div>
							<div className="col-xl-4 col-sm-6">
								<div className="single-contact-info">
									<p><i className="fa fa-phone" />Address</p>
									<h5>{address.streetOne}, {address.city},</h5>
									<h5>{address.state} {address.zip}, {address.country}</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
				</>
			}
		</>
	);
}

export default Contact;
