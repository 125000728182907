import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ReusableCarousel({
  photos
}) {
  return (
    <div>
      <Carousel infiniteLoop={true} autoPlay={true} dynamicHeight={true} >
        {photos.map((k, i) => {
          return (
            <div>
              <img
                src={k}
                id={i}
              />
            </div>
          )
        })}
      </Carousel>
    </div>
  );
}