import React, { useState, useEffect } from "react";
import { CALENDAR_SERVICE_URL, MICROSITE_INFO } from "./utils/constants";
import {
  locationGql,
  unitsGql,
  unitTypeGql,
  orgDetailsGql,
  amenitiesGql,
  toursGql,
  tourTypesGql,
} from "./store/schemas/location";
import { googleEventsGql } from "./store/schemas/calendar";
import { getClient } from "./init-apollo-googleFn";
import placeholderPhoto from "./assets/banner/header.jpg";

const Context = React.createContext();

const micrositeClient = getClient(MICROSITE_INFO);
const calendarClient = getClient(CALENDAR_SERVICE_URL);

function ContextProvider({ children }) {
  const [amenitiesObj, setAmenitiesObj] = useState(null); // usee to attach amities to units in MainContent
  // const [fromDate, setFromDate] = useState(""); // Being used?
  const [location, setLocation] = useState(null); // Property Data
  const [locationAmenities, setLocationAmenities] = useState(null);
  const [locationId, setLocationId] = useState(null); // Used to query location data
  const [orgId, setOrgId] = useState(null); // needed to set above
  const [photosArray, setPhotosArray] = useState([]); // Global property photos
  const [template, setTemplate] = useState(""); // Used to check which template to render in MainContent
  const [tours, setTours] = useState("");
  const [tourTypes, setTourTypes] = useState(""); // Retrieves property's tour types
  const [units, setUnits] = useState(null); // needed in MainContent only
  const [unitType, setUnitType] = useState(null);
  const [updatedUnits, setUpdatedUnits] = useState([]); // Comprised of units, their associated floor plans
  const [calendarEvents, setCalendarEvents] = useState([]); // Google calendar events

  // useEffect(() => {
  //   let today = new Date();
  //   let dd = String(today.getDate()).padStart(2, '0');
  //   let mm = String(today.getMonth() + 1).padStart(2, '0');
  //   let yyyy = today.getFullYear();

  //   today = mm + '/' + dd + '/' + yyyy;

  //   setFromDate(today);
  // }, [])

  const getLocations = (locationId) => {
    try {
      micrositeClient
        .query({
          query: locationGql,
          variables: {
            locationId: locationId,
          },
        })
        .then((response) => {
          const photos = response.data.location.edges[0].node.photos;
          const org = response.data.location.edges[0].node.organizationId;

          setLocation(response.data.location.edges[0].node);
          setPhotosArray(
            photos === "[]" || photos === null
              ? [placeholderPhoto]
              : JSON.parse(photos)
          );
          setOrgId(response.data.location.edges[0].node.organizationId);
          setTemplate(response.data.location.edges[0].node.templateId);
          getOrgDetails(org);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const getAmenities = () => {
    try {
      micrositeClient
        .query({
          query: amenitiesGql,
        })
        .then((response) => {
          setAmenitiesObj(response.data.amenities.edges);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const getOrgDetails = (org) => {
    try {
      micrositeClient
        .query({
          query: orgDetailsGql,
          variables: {
            orgId: org,
          },
        })
        .then((response) => {
          localStorage.setItem(
            "orgDetails",
            JSON.stringify(response.data.org.edges[0].node)
          );
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const getUnitType = (locationId) => {
    try {
      micrositeClient
        .query({
          query: unitTypeGql,
          variables: {
            locationId: locationId,
          },
        })
        .then((response) => {
          setUnitType(response.data.unitType.edges);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const getUnits = (locationId) => {
    try {
      micrositeClient
        .query({
          query: unitsGql,
          variables: {
            locationId: locationId,
          },
        })
        .then((response) => {
          setUnits(response.data.units.edges);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const getTours = (locationId) => {
    try {
      micrositeClient
        .query({
          query: toursGql,
          variables: {
            location: locationId,
          },
        })
        .then((response) => {
          setTours(response.data.tours.edges);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };
  const getTourTypes = (locationId) => {
    try {
      micrositeClient
        .query({
          query: tourTypesGql,
          variables: {
            location: locationId,
          },
        })
        .then((response) => {
          setTourTypes(
            response.data.tourTypes.edges.filter((t) => t.node.selfBooking)
          );
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const getEvents = async ({ startDate, endDate, timezone }) => {
    try {
      const events = await calendarClient.query({
        query: googleEventsGql,
        variables: {
          startDate,
          endDate,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      setCalendarEvents(events);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Context.Provider
      value={{
        amenitiesObj,
        location,
        setLocation,
        locationAmenities,
        setLocationAmenities,
        locationId,
        setLocationId,
        orgId,
        // fromDate,
        photosArray,
        template,
        setTemplate,
        tours,
        tourTypes,
        unitType,
        setUnitType,
        units,
        setUnits,
        updatedUnits,
        setUpdatedUnits,
        // findUnitAmenities, // function
        getAmenities,
        getOrgDetails,
        getTours,
        getTourTypes,
        getUnitType, // func
        getUnits, // func
        getLocations, // func
        getEvents, // func
        calendarEvents,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, ContextProvider };
