import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';
import '../../style.scss';

function Footer_v1() {
	const { location } = useContext(Context);
	const address = JSON.parse(location.addresses)[0];
	const { socials } = JSON.parse(location.contact);
	const contact = JSON.parse(location.contact);

	const publicUrl = process.env.PUBLIC_URL+'/'
	useEffect(() => {
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);
	})

	return (
		<footer className="footer-area">
			{socials && (socials.facebook || socials.twitter || socials.linkedIn || socials.instagram || socials.yelp) &&
				<div className="container">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-8 text-md-right mt-3 mt-md-0">
							<ul className="social-area" style={{position: 'absolute', right: '0px', bottom: '26px'}}>
								{socials.facebook &&
									<li>
										<a className='socials-background' href={`https://http://${socials.facebook}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-facebook-f" aria-hidden="true" /></a>
									</li>
								}
								{socials.twitter &&      
									<li>
										<a className='socials-background' href={`https://http://${socials.twitter}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-twitter" aria-hidden="true" /></a>
									</li>
								}
								{socials.linkedIn &&      
									<li>
										<a className='socials-background' href={`http://${socials.linkedIn}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-linkedin" aria-hidden="true" /></a>
									</li>
								}
								{socials.instagram &&      
									<li>
										<a className='socials-background' href={`http://${socials.instagram}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-instagram" aria-hidden="true" /></a>
									</li>
								}
								{socials.yelp &&      
									<li>
										<a className='socials-background' href={`http://${socials.yelp}`} target="_blank" rel='noopener noreferrer'><i className="fa fa-yelp" aria-hidden="true" /></a>
									</li>
								}
							</ul>
						</div>
					</div>
				</div>
			}
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-md-7 align-self-center">
							<div className='contact-link'>
								<h4 className="widget-title contact-icon" style={{fontSize: '24px'}}>{location.name}</h4>
							</div>
								<div className="details contact-link">
									<i className="fa fa-map-marker contact-icon" /> <span style={{color: '#ffffffcc'}}>{address.streetOne}, {address.city}, {address.state}, {address.country}</span>
								</div>
						</div>
						<div className="col-md-5 text-md-right go-top">
							<ul>
								<li className='contact-link'>
									<Link
										className='contact-icon'
										to="/"
										onClick={()=> {
											window.scrollTo(0,0);
										}}
									>
										Home
									</Link>
								</li>
								<li className='contact-link'>
									<Link
										className='contact-icon'
										to="/contact"
										onClick={()=> {
											window.scrollTo(0,0);
										}}>
											Contact
										</Link>
								</li>
							</ul>
							<div style={{display: 'inline-block', marginTop: '30px', marginRight: '20px'}}>
								<a className="contact-link" href={`tel:${contact.phone}`}><i className="fa fa-phone contact-icon" /> <span style={{color: '#ffffffcc'}}>{`+1 (${contact.phone.slice(0, 3)}) ${contact.phone.slice(3, 6)} ${contact.phone.slice(6)}`}</span></a>
							</div>
							<div style={{display: 'inline-block'}}>
								<a className='contact-link'href={`mailto: ${contact.businessEmail}`}><i className="fa fa-envelope contact-icon" /> <span style={{color: '#ffffffcc'}}>{contact.email}</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>

	)
}



export default Footer_v1