import React, { useState, useEffect, useRef } from 'react';
import TourForm from './TourForm';

const TourPopup  = (props) =>{
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(!isOpen)

  const tourCircle = {
    position: "fixed",
    bottom: "50px",
    right: "50px",
    background: props.background,
    width: "80px",
    height: "80px",  
    borderRadius: "50%",
    color: "white",
    padding: "28px",
    cursor: "pointer",
    zIndex: "101",
    boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.6), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
  }

  const iconStyle = {
    color: "white",
    webkitTextStrokeWidth: "1px",
    webkitTextStrokeColor: "black",
    cursor: "pointer",
    zIndex: "100",
  };
  
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
          }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
      <button 
        style={tourCircle}
        type="click"
        onClick={handleOpen}
      >
        <i
          className={isOpen ? "fa fa-times-circle" : "fa fa-home"}
          style={iconStyle}
        />
      </button>
      {
        isOpen && <TourForm submitStyle={props.submitStyle} />
      }
    </div>
  );
}

export default TourPopup;