import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import PropertyGrid from './section-components/property-grid';
import Footer from './global-components/footer';

const PropertyGridPage = () => {
    return <div>
        <Navbar />
        <PageHeader from="units" headertitle="Property Grid"  />
        <PropertyGrid />
        <Footer />
    </div>
}

export default PropertyGridPage

