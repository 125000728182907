import React, { useContext } from 'react';
import { Context } from '../../../../../AppContext';
import pic1 from '../../../public/assets/img/icons/10.png'
import pic2 from '../../../public/assets/img/icons/11.png'
import pic3 from '../../../public/assets/img/icons/12.png'

function ContactInfo() {
	const { location } = useContext(Context);
	const contact = JSON.parse(location.contact);
	const orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
	const address = JSON.parse(orgDetails.address);

	return (
		orgDetails !== undefined &&
		<div className="ltn__contact-address-area mb-90">
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow" style={{height: '322px'}}>
							<div className="ltn__contact-address-icon">
								<img src={pic1} alt="Icon Image" />
							</div>
							<h3 style={{marginBottom: '0px'}}>Company Email</h3>
							<a style={{display: 'block', marginBottom: '10px'}} href={`mailto:${orgDetails.businessEmail}`}>{orgDetails.businessEmail}</a>
							<h3 style={{marginBottom: '0px'}}>Office Email</h3>
							<a href={`mailto:${contact.email}`}>{contact.email}</a>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow" style={{height: '322px'}}>
							<div className="ltn__contact-address-icon">
								<img src={pic2} alt="Icon Image" />
							</div>
							<h3 style={{marginBottom: '0px'}}>Company Phone</h3>
							<a style={{display: 'block', marginBottom: '10px'}} href={`tel:${orgDetails.phone}`}>{`+1 (${orgDetails.phone.slice(0, 3)}) ${orgDetails.phone.slice(3, 6)} ${orgDetails.phone.slice(6)}`}</a>
							<h3 style={{marginBottom: '0px'}}>Office Number</h3>
							<a href={`tel:${contact.phone}`}>{`+1 (${contact.phone.slice(0, 3)}) ${contact.phone.slice(3, 6)} ${contact.phone.slice(6)}`}</a>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow" style={{height: '322px'}}>
							<div className="ltn__contact-address-icon">
								<img src={pic3} alt="Icon Image" />
							</div>
							<h3 style={{marginBottom: '0px'}}>Office Address</h3>
							<p>{address.street_one}<br />
							{address.city}, {address.country}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactInfo