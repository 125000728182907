import React, { useContext, useState , useEffect } from 'react';
import { Context } from '../../../../AppContext';

function AboutV2()  {
  const { location, locationAmenities, updatedUnits} = useContext(Context);

  const sortedUnitAmenities = updatedUnits.map((k, i ) => {
    return k.amenities;
  }).sort((a, b) => {
    return b.length - a.length;
  })

  const miniArray = locationAmenities.length > 0 ? locationAmenities.slice(0, 9) : sortedUnitAmenities[0].slice(0, 9);

  return (
    <div className="about-area bg-gray pd-top-100 pd-bottom-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 align-self-center">
            <div className="section-title mb-lg-0">
              <h2 className="title" style={{color: '#fda94f'}} dangerouslySetInnerHTML={{__html: `About ${location.customId}`}}></h2>
              <p>{ location.description }</p>
            </div>
          </div>
          <div className="col-lg-7"
          >
            {/* building/community amenities */}
            {miniArray &&
              <div className="row amenities-overflow scroll">
                {miniArray.map( ( item, i ) =>
                  <div key={ i } className="col-md-4 col-sm-6">
                    <div className="cat-single text-center">
                      <i className="fa fa-check" />
                      <p>{item}</p>
                      {/* <p>Placeholder</p> */}
                    </div>
                  </div>
                ) }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutV2