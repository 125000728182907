import React from 'react';


function Error ()  {




  return (
      <div>
        <div className="error-page text-center">
          <div className="container">
            <div className="error-page-wrap d-inline-block">
              <h2>404</h2>
            </div>
          </div>
        </div>
      </div>
    ) 
  }

export default Error