import React, { useContext } from 'react';
import { Context } from '../../../../../AppContext';
import bannerVid from '../../../../../assets/banner/header-vid.webm';

function Availability() {
	const { updatedUnits } = useContext(Context);
	const vacantUnits = updatedUnits.filter(a => a.node.status === "vacant");
	const orderedUnits = [...vacantUnits].sort((a, b) => a.node.number - b.node.number);

	return (
		<>
			{/* Add below once query is added */}
			{/* <video autoPlay loop muted id='video' style={{width: '100vw', marginBottom: '100px'}}>
				<source src={bannerVid} type='video/mp4'/>
			</video> */}
			<div className="select-availability-area pb-120">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area ltn__section-title-2--- text-center---">
								<h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Avialable Spaces</h6>
								<h1 className="section-title">Select Availability</h1>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="ltn__select-availability-table-wrap">
								<div className="ltn__select-availability-table d-none d-md-block">
									<ul className="ltn__select-availability-table-head">
										<li>Residence</li>
										<li>Bedrooms</li>
										<li>Bathroom</li>
										<li>SQ.FT </li>
										<li>Rent Price</li>
										<li>Details</li>
									</ul>
									{orderedUnits.map((k) => {
										const { node } = k;
										return (
											<ul className="ltn__select-availability-table-row">
												<li className="tower-name">{node.unitType.type}</li>
												<li>{node.bedrooms}</li>
												<li>{node.bathrooms}</li>
												<li>{node.sqft}</li>
												<li>${node.price}</li>
												<li><a href="product-details.html">+ Available</a></li>
											</ul>
										);
									})}
								</div>
								<div className="ltn__select-availability-table-responsive d-md-none">
									{orderedUnits.map((k) => {
										const { node } = k;
										return (
											<ul className="ltn__select-availability-table-row-responsive-item">
												<li><span>Residence</span> <span className="tower-name">{node.unitType.type}</span></li>
												<li><span>Bedrooms</span> <span>{node.bedrooms}</span></li>
												<li><span>Bathroom</span> <span>{node.bathrooms}</span></li>
												<li><span>SQ.FT</span> <span>{node.sqft}</span></li>
												<li><span>Rent Price</span> <span>${node.price}</span></li>
												<li><span>Details</span> <span><a href="product-details.html">+ Available</a></span></li>
											</ul>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Availability