export const MICROSITE_INFO = `https://service-microsite-cg2k2cekgq-uc.a.run.app/microsite/graphql`;
export const CALENDAR_SERVICE_URL ="https://service-calendar-cg2k2cekgq-uc.a.run.app/calendar/graphql";
export const APPLICATION_RENTER = `https://service-application-renter-cg2k2cekgq-uc.a.run.app/application_renter/graphql`
export const APPLICATION_PUBLIC = `https://service-leasera-application-cg2k2cekgq-uc.a.run.app/application/graphql`
export const PAYMENTS_URL = `https://payment-dev-dot-leasera-staging.uc.r.appspot.com/graphql`


  export const optionsCountry = [{text: 'Afghanistan', value: 'AF'}, 
  {text: 'Åland Islands', value: 'AX'}, 
  {text: 'Albania', value: 'AL'}, 
  {text: 'Algeria', value: 'DZ'}, 
  {text: 'American Samoa', value: 'AS'}, 
  {text: 'AndorrA', value: 'AD'}, 
  {text: 'Angola', value: 'AO'}, 
  {text: 'Anguilla', value: 'AI'}, 
  {text: 'Antarctica', value: 'AQ'}, 
  {text: 'Antigua and Barbuda', value: 'AG'}, 
  {text: 'Argentina', value: 'AR'}, 
  {text: 'Armenia', value: 'AM'}, 
  {text: 'Aruba', value: 'AW'}, 
  {text: 'Australia', value: 'AU'}, 
  {text: 'Austria', value: 'AT'}, 
  {text: 'Azerbaijan', value: 'AZ'}, 
  {text: 'Bahamas', value: 'BS'}, 
  {text: 'Bahrain', value: 'BH'}, 
  {text: 'Bangladesh', value: 'BD'}, 
  {text: 'Barbados', value: 'BB'}, 
  {text: 'Belarus', value: 'BY'}, 
  {text: 'Belgium', value: 'BE'}, 
  {text: 'Belize', value: 'BZ'}, 
  {text: 'Benin', value: 'BJ'}, 
  {text: 'Bermuda', value: 'BM'}, 
  {text: 'Bhutan', value: 'BT'}, 
  {text: 'Bolivia', value: 'BO'}, 
  {text: 'Bosnia and Herzegovina', value: 'BA'}, 
  {text: 'Botswana', value: 'BW'}, 
  {text: 'Bouvet Island', value: 'BV'}, 
  {text: 'Brazil', value: 'BR'}, 
  {text: 'British Indian Ocean Territory', value: 'IO'}, 
  {text: 'Brunei Darussalam', value: 'BN'}, 
  {text: 'Bulgaria', value: 'BG'}, 
  {text: 'Burkina Faso', value: 'BF'}, 
  {text: 'Burundi', value: 'BI'}, 
  {text: 'Cambodia', value: 'KH'}, 
  {text: 'Cameroon', value: 'CM'}, 
  {text: 'Canada', value: 'CA'}, 
  {text: 'Cape Verde', value: 'CV'}, 
  {text: 'Cayman Islands', value: 'KY'}, 
  {text: 'Central African Republic', value: 'CF'}, 
  {text: 'Chad', value: 'TD'}, 
  {text: 'Chile', value: 'CL'}, 
  {text: 'China', value: 'CN'}, 
  {text: 'Christmas Island', value: 'CX'}, 
  {text: 'Cocos (Keeling) Islands', value: 'CC'}, 
  {text: 'Colombia', value: 'CO'}, 
  {text: 'Comoros', value: 'KM'}, 
  {text: 'Congo', value: 'CG'}, 
  {text: 'Congo, The Democratic Republic of the', value: 'CD'}, 
  {text: 'Cook Islands', value: 'CK'}, 
  {text: 'Costa Rica', value: 'CR'}, 
  {text: 'Cote D\'Ivoire', value: 'CI'}, 
  {text: 'Croatia', value: 'HR'}, 
  {text: 'Cuba', value: 'CU'}, 
  {text: 'Cyprus', value: 'CY'}, 
  {text: 'Czech Republic', value: 'CZ'}, 
  {text: 'Denmark', value: 'DK'}, 
  {text: 'Djibouti', value: 'DJ'}, 
  {text: 'Dominica', value: 'DM'}, 
  {text: 'Dominican Republic', value: 'DO'}, 
  {text: 'Ecuador', value: 'EC'}, 
  {text: 'Egypt', value: 'EG'}, 
  {text: 'El Salvador', value: 'SV'}, 
  {text: 'Equatorial Guinea', value: 'GQ'}, 
  {text: 'Eritrea', value: 'ER'}, 
  {text: 'Estonia', value: 'EE'}, 
  {text: 'Ethiopia', value: 'ET'}, 
  {text: 'Falkland Islands (Malvinas)', value: 'FK'}, 
  {text: 'Faroe Islands', value: 'FO'}, 
  {text: 'Fiji', value: 'FJ'}, 
  {text: 'Finland', value: 'FI'}, 
  {text: 'France', value: 'FR'}, 
  {text: 'French Guiana', value: 'GF'}, 
  {text: 'French Polynesia', value: 'PF'}, 
  {text: 'French Southern Territories', value: 'TF'}, 
  {text: 'Gabon', value: 'GA'}, 
  {text: 'Gambia', value: 'GM'}, 
  {text: 'Georgia', value: 'GE'}, 
  {text: 'Germany', value: 'DE'}, 
  {text: 'Ghana', value: 'GH'}, 
  {text: 'Gibraltar', value: 'GI'}, 
  {text: 'Greece', value: 'GR'}, 
  {text: 'Greenland', value: 'GL'}, 
  {text: 'Grenada', value: 'GD'}, 
  {text: 'Guadeloupe', value: 'GP'}, 
  {text: 'Guam', value: 'GU'}, 
  {text: 'Guatemala', value: 'GT'}, 
  {text: 'Guernsey', value: 'GG'}, 
  {text: 'Guinea', value: 'GN'}, 
  {text: 'Guinea-Bissau', value: 'GW'}, 
  {text: 'Guyana', value: 'GY'}, 
  {text: 'Haiti', value: 'HT'}, 
  {text: 'Heard Island and Mcdonald Islands', value: 'HM'}, 
  {text: 'Holy See (Vatican City State)', value: 'VA'}, 
  {text: 'Honduras', value: 'HN'}, 
  {text: 'Hong Kong', value: 'HK'}, 
  {text: 'Hungary', value: 'HU'}, 
  {text: 'Iceland', value: 'IS'}, 
  {text: 'India', value: 'IN'}, 
  {text: 'Indonesia', value: 'ID'}, 
  {text: 'Iran, Islamic Republic Of', value: 'IR'}, 
  {text: 'Iraq', value: 'IQ'}, 
  {text: 'Ireland', value: 'IE'}, 
  {text: 'Isle of Man', value: 'IM'}, 
  {text: 'Israel', value: 'IL'}, 
  {text: 'Italy', value: 'IT'}, 
  {text: 'Jamaica', value: 'JM'}, 
  {text: 'Japan', value: 'JP'}, 
  {text: 'Jersey', value: 'JE'}, 
  {text: 'Jordan', value: 'JO'}, 
  {text: 'Kazakhstan', value: 'KZ'}, 
  {text: 'Kenya', value: 'KE'}, 
  {text: 'Kiribati', value: 'KI'}, 
  {text: 'Korea, Democratic People\'S Republic of', value: 'KP'}, 
  {text: 'Korea, Republic of', value: 'KR'}, 
  {text: 'Kuwait', value: 'KW'}, 
  {text: 'Kyrgyzstan', value: 'KG'}, 
  {text: 'Lao People\'S Democratic Republic', value: 'LA'}, 
  {text: 'Latvia', value: 'LV'}, 
  {text: 'Lebanon', value: 'LB'}, 
  {text: 'Lesotho', value: 'LS'}, 
  {text: 'Liberia', value: 'LR'}, 
  {text: 'Libyan Arab Jamahiriya', value: 'LY'}, 
  {text: 'Liechtenstein', value: 'LI'}, 
  {text: 'Lithuania', value: 'LT'}, 
  {text: 'Luxembourg', value: 'LU'}, 
  {text: 'Macao', value: 'MO'}, 
  {text: 'Macedonia, The Former Yugoslav Republic of', value: 'MK'}, 
  {text: 'Madagascar', value: 'MG'}, 
  {text: 'Malawi', value: 'MW'}, 
  {text: 'Malaysia', value: 'MY'}, 
  {text: 'Maldives', value: 'MV'}, 
  {text: 'Mali', value: 'ML'}, 
  {text: 'Malta', value: 'MT'}, 
  {text: 'Marshall Islands', value: 'MH'}, 
  {text: 'Martinique', value: 'MQ'}, 
  {text: 'Mauritania', value: 'MR'}, 
  {text: 'Mauritius', value: 'MU'}, 
  {text: 'Mayotte', value: 'YT'}, 
  {text: 'Mexico', value: 'MX'}, 
  {text: 'Micronesia, Federated States of', value: 'FM'}, 
  {text: 'Moldova, Republic of', value: 'MD'}, 
  {text: 'Monaco', value: 'MC'}, 
  {text: 'Mongolia', value: 'MN'}, 
  {text: 'Montserrat', value: 'MS'}, 
  {text: 'Morocco', value: 'MA'}, 
  {text: 'Mozambique', value: 'MZ'}, 
  {text: 'Myanmar', value: 'MM'}, 
  {text: 'Namibia', value: 'NA'}, 
  {text: 'Nauru', value: 'NR'}, 
  {text: 'Nepal', value: 'NP'}, 
  {text: 'Netherlands', value: 'NL'}, 
  {text: 'Netherlands Antilles', value: 'AN'}, 
  {text: 'New Caledonia', value: 'NC'}, 
  {text: 'New Zealand', value: 'NZ'}, 
  {text: 'Nicaragua', value: 'NI'}, 
  {text: 'Niger', value: 'NE'}, 
  {text: 'Nigeria', value: 'NG'}, 
  {text: 'Niue', value: 'NU'}, 
  {text: 'Norfolk Island', value: 'NF'}, 
  {text: 'Northern Mariana Islands', value: 'MP'}, 
  {text: 'Norway', value: 'NO'}, 
  {text: 'Oman', value: 'OM'}, 
  {text: 'Pakistan', value: 'PK'}, 
  {text: 'Palau', value: 'PW'}, 
  {text: 'Palestinian Territory, Occupied', value: 'PS'}, 
  {text: 'Panama', value: 'PA'}, 
  {text: 'Papua New Guinea', value: 'PG'}, 
  {text: 'Paraguay', value: 'PY'}, 
  {text: 'Peru', value: 'PE'}, 
  {text: 'Philippines', value: 'PH'}, 
  {text: 'Pitcairn', value: 'PN'}, 
  {text: 'Poland', value: 'PL'}, 
  {text: 'Portugal', value: 'PT'}, 
  {text: 'Puerto Rico', value: 'PR'}, 
  {text: 'Qatar', value: 'QA'}, 
  {text: 'Reunion', value: 'RE'}, 
  {text: 'Romania', value: 'RO'}, 
  {text: 'Russian Federation', value: 'RU'}, 
  {text: 'RWANDA', value: 'RW'}, 
  {text: 'Saint Helena', value: 'SH'}, 
  {text: 'Saint Kitts and Nevis', value: 'KN'}, 
  {text: 'Saint Lucia', value: 'LC'}, 
  {text: 'Saint Pierre and Miquelon', value: 'PM'}, 
  {text: 'Saint Vincent and the Grenadines', value: 'VC'}, 
  {text: 'Samoa', value: 'WS'}, 
  {text: 'San Marino', value: 'SM'}, 
  {text: 'Sao Tome and Principe', value: 'ST'}, 
  {text: 'Saudi Arabia', value: 'SA'}, 
  {text: 'Senegal', value: 'SN'}, 
  {text: 'Serbia and Montenegro', value: 'CS'}, 
  {text: 'Seychelles', value: 'SC'}, 
  {text: 'Sierra Leone', value: 'SL'}, 
  {text: 'Singapore', value: 'SG'}, 
  {text: 'Slovakia', value: 'SK'}, 
  {text: 'Slovenia', value: 'SI'}, 
  {text: 'Solomon Islands', value: 'SB'}, 
  {text: 'Somalia', value: 'SO'}, 
  {text: 'South Africa', value: 'ZA'}, 
  {text: 'South Georgia and the South Sandwich Islands', value: 'GS'}, 
  {text: 'Spain', value: 'ES'}, 
  {text: 'Sri Lanka', value: 'LK'}, 
  {text: 'Sudan', value: 'SD'}, 
  {text: 'Suriname', value: 'SR'}, 
  {text: 'Svalbard and Jan Mayen', value: 'SJ'}, 
  {text: 'Swaziland', value: 'SZ'}, 
  {text: 'Sweden', value: 'SE'}, 
  {text: 'Switzerland', value: 'CH'}, 
  {text: 'Syrian Arab Republic', value: 'SY'}, 
  {text: 'Taiwan, Province of China', value: 'TW'}, 
  {text: 'Tajikistan', value: 'TJ'}, 
  {text: 'Tanzania, United Republic of', value: 'TZ'}, 
  {text: 'Thailand', value: 'TH'}, 
  {text: 'Timor-Leste', value: 'TL'}, 
  {text: 'Togo', value: 'TG'}, 
  {text: 'Tokelau', value: 'TK'}, 
  {text: 'Tonga', value: 'TO'}, 
  {text: 'Trinidad and Tobago', value: 'TT'}, 
  {text: 'Tunisia', value: 'TN'}, 
  {text: 'Turkey', value: 'TR'}, 
  {text: 'Turkmenistan', value: 'TM'}, 
  {text: 'Turks and Caicos Islands', value: 'TC'}, 
  {text: 'Tuvalu', value: 'TV'}, 
  {text: 'Uganda', value: 'UG'}, 
  {text: 'Ukraine', value: 'UA'}, 
  {text: 'United Arab Emirates', value: 'AE'}, 
  {text: 'United Kingdom', value: 'GB'}, 
  {text: 'United States', value: 'US'}, 
  {text: 'United States Minor Outlying Islands', value: 'UM'}, 
  {text: 'Uruguay', value: 'UY'}, 
  {text: 'Uzbekistan', value: 'UZ'}, 
  {text: 'Vanuatu', value: 'VU'}, 
  {text: 'Venezuela', value: 'VE'}, 
  {text: 'Viet Nam', value: 'VN'}, 
  {text: 'Virgin Islands, British', value: 'VG'}, 
  {text: 'Virgin Islands, U.S.', value: 'VI'}, 
  {text: 'Wallis and Futuna', value: 'WF'}, 
  {text: 'Western Sahara', value: 'EH'}, 
  {text: 'Yemen', value: 'YE'}, 
  {text: 'Zambia', value: 'ZM'}, 
  {text: 'Zimbabwe', value: 'ZW'} ] 

  export const optionsMonth = [
    {
      value:"January",
      key: "01",
      text:"January"
    },
    {
      value:"February",
      key: "02",
      text:"February"
    },
    {
      value:"March",
      key: "03",
      text:"March"
    },
    {
      value:"April",
      key: "04",
      text:"April"
    },
    {
      value:"May",
      key: "05",
      text:"May"
    },
    {
      value:"June",
      key: "06",
      text:"June"
    },
    {
      value:"July",
      key: "07",
      text:"July"
    },
    {
      value:"August",
      key: "08",
      text:"August"
    },
    {
      value:"September",
      key: "09",
      text:"September"
    },
    {
      value:"October",
      key: "10",
      text:"October"
    },
    {
      value:"November",
      key: "11",
      text:"November"
    },
    {
      value:"December",
      key: "12",
      text:"December"
    },
  ]
  export const optionsMonthNumber = [
    {
      value:"01",
      key: "01",
      text:"1-January"
    },
    {
      value:"02",
      key: "02",
      text:"2-February"
    },
    {
      value:"03",
      key: "03",
      text:"3-March"
    },
    {
      value:"04",
      key: "04",
      text:"4-April"
    },
    {
      value:"05",
      key: "05",
      text:"5-May"
    },
    {
      value:"06",
      key: "06",
      text:"6-June"
    },
    {
      value:"07",
      key: "07",
      text:"7-July"
    },
    {
      value:"08",
      key: "08",
      text:"8-August"
    },
    {
      value:"09",
      key: "09",
      text:"9-September"
    },
    {
      value:"10",
      key: "10",
      text:"10-October"
    },
    {
      value:"11",
      key: "11",
      text:"11-November"
    },
    {
      value:"12",
      key: "12",
      text:"12-December"
    },
  ]
  export const optionsYearCard = [
    {
      value:"2022",
      key: "1",
      text:"2022"
    },
    {
      value:"2023",
      key: "2",
      text:"2023"
    },
    {
      value:"2024",
      key: "3",
      text:"2024"
    },
    {
      value:"2025",
      key: "4",
      text:"2025"
    },
    {
      value:"2026",
      key: "5",
      text:"2026"
    },
    {
      value:"2027",
      key: "6",
      text:"2027"
    },
    {
      value:"2028",
      key: "7",
      text:"2028"
    },
    {
      value:"2029",
      key: "8",
      text:"2029"
    },
    {
      value:"2030",
      key: "9",
      text:"2030"
    },
  ]

  export const optionsYear = [
    {
      value:"2022",
      key: "1",
      text:"2022"
    },
    {
      value:"2021",
      key: "2",
      text:"2021"
    },
    {
      value:"2020",
      key: "3",
      text:"2020"
    },
    {
      value:"2019",
      key: "4",
      text:"2019"
    },
    {
      value:"2018",
      key: "5",
      text:"2018"
    },
    {
      value:"2017",
      key: "6",
      text:"2017"
    },
    {
      value:"2016",
      key: "7",
      text:"2016"
    },
    {
      value:"2015",
      key: "8",
      text:"2015"
    },
    {
      value:"2014",
      key: "9",
      text:"2014"
    },
    {
      value:"2013",
      key: "10",
      text:"2013"
    },
    {
      value:"2012",
      key: "11",
      text:"2012"
    },
    {
      value:"2011",
      key: "12",
      text:"2011"
    },
    {
      value:"2010",
      key: "13",
      text:"2010"
    },
    {
      value:"2009",
      key: "14",
      text:"2009"
    },
    {
      value:"2008",
      key: "15",
      text:"2008"
    },
    {
      value:"2007",
      key: "16",
      text:"2007"
    },
    {
      value:"2006",
      key: "17",
      text:"2006"
    },
    {
      value:"2005",
      key: "18",
      text:"2005"
    },
    {
      value:"2004",
      key: "19",
      text:"2004"
    },
    {
      value:"2003",
      key: "20",
      text:"2003"
    },
    {
      value:"2002",
      key: "21",
      text:"2002"
    },
    {
      value:"2001",
      key: "22",
      text:"2001"
    },
    {
      value:"2000",
      key: "23",
      text:"2000"
    },
    {
      value:"1999",
      key: "24",
      text:"1999"
    },
  ]

  export const optionsState = [
    { value: "AK", text: "Alaska" },
    { value: "AL", text: "Alabama" },
    { value: "AR", text: "Arkansas" },
    { value: "AS", text: "American Samoa" },
    { value: "AZ", text: "Arizona" },
    { value: "CA", text: "California" },
    { value: "CO", text: "Colorado" },
    { value: "CT", text: "Connecticut" },
    { value: "DC", text: "District of Columbia" },
    { value: "DE", text: "Delaware" },
    { value: "FL", text: "Florida" },
    { value: "GA", text: "Georgia" },
    { value: "GU", text: "Guam" },
    { value: "HI", text: "Hawaii" },
    { value: "IA", text: "Iowa" },
    { value: "ID", text: "Idaho" },
    { value: "IL", text: "Illinois" },
    { value: "IN", text: "Indiana" },
    { value: "KS", text: "Kansas" },
    { value: "KY", text: "Kentucky" },
    { value: "LA", text: "Louisiana" },
    { value: "MA", text: "Massachusetts" },
    { value: "MD", text: "Maryland" },
    { value: "ME", text: "Maine" },
    { value: "MI", text: "Michigan" },
    { value: "MN", text: "Minnesota" },
    { value: "MO", text: "Missouri" },
    { value: "MS", text: "Mississippi" },
    { value: "MT", text: "Montana" },
    { value: "NC", text: "North Carolina" },
    { value: "ND", text: "North Dakota" },
    { value: "NE", text: "Nebraska" },
    { value: "NH", text: "New Hampshire" },
    { value: "NJ", text: "New Jersey" },
    { value: "NM", text: "New Mexico" },
    { value: "NV", text: "Nevada" },
    { value: "NY", text: "New York" },
    { value: "OH", text: "Ohio" },
    { value: "OK", text: "Oklahoma" },
    { value: "OR", text: "Oregon" },
    { value: "PA", text: "Pennsylvania" },
    { value: "PR", text: "Puerto Rico" },
    { value: "RI", text: "Rhode Island" },
    { value: "SC", text: "South Carolina" },
    { value: "SD", text: "South Dakota" },
    { value: "TN", text: "Tennessee" },
    { value: "TX", text: "Texas" },
    { value: "UT", text: "Utah" },
    { value: "VA", text: "Virginia" },
    { value: "VI", text: "Virgin Islands" },
    { value: "VT", text: "Vermont" },
    { value: "WA", text: "Washington" },
    { value: "WI", text: "Wisconsin" },
    { value: "WV", text: "West Virginia" },
    { value: "WY", text: "Wyoming" }
    ]

    export function formatPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
  }

  export function formatPhoneNumberCard(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{4})(\d{4})(\d{4})(\d{4})$/);
    if (match) {
        return [ match[2], '-', match[3], '-',  match[4],'-',match[5]].join('');
    }
    return null;
}


    export const optionsHow = [
      { key: '1', text: 'Agent', value: 'agent' },
      { key: '2', text: 'Drive-By', value: 'driveby' },
      { key: '3', text: 'Friend/Family', value: 'friendfamily' },
      { key: '4', text: 'Company Website', value: 'companywebsite' },
      { key: '5', text: 'CraigsList', value: 'craigslist' },
      { key: '6', text: 'Facebook', value: 'facebook' },
      { key: '7', text: 'Instagram', value: 'instagram' },
      { key: '8', text: 'Snapchat', value: 'snapchat' },
      { key: '9', text: 'Twitter', value: 'twitter' },
      { key: '0', text: 'Other', value: 'other' },
      { key: '11', text: 'Apartment Guide', value: 'apartmentguide' },
      { key: '12', text: 'ApartmentList', value: 'apartmentlist' },
      { key: '13', text: 'Apartments.com', value: 'apartments.com' },
      { key: '14', text: 'ForRent', value: 'forrent' },
      { key: '15', text: 'FRBO', value: 'frbo' },
      { key: '16', text: 'Homes.com', value: 'homes.com' },
      { key: '17', text: 'HotPads', value: 'hotpads' },
      { key: '18', text: 'Lovely', value: 'lovely' },
      { key: '19', text: 'Move.com', value: 'move.com' },
      { key: '20', text: 'PadMapper', value: 'padmapper' },
      { key: '21', text: 'RadPad', value: 'radpad' },
      { key: '22', text: 'Rent.com', value: 'rent.com' },
      { key: '23', text: 'RentalAds.com', value: 'rentalads.com' },
      { key: '24', text: 'Rentals.com', value: 'rentals.com' },
      { key: '25', text: 'ShowMeTheRent', value: 'showmetherent' },
      { key: '26', text: 'Trulia', value: 'trulia' },
      { key: '27', text: 'WalkScore', value: 'walkscore' },
      { key: '28', text: 'Zillow', value: 'zillow' },
      { key: '29', text: 'Zumper', value: 'zumper' },
    ]