import React from 'react';
import MainContent from './MainContent';
import { ContextProvider } from './AppContext';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <>
      <ContextProvider>
        <MainContent/>
      </ContextProvider>
    </>
  );
}

export default App;