import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../AppContext';
import MapContainer from '../../../../../shared_components/google-map';
import { leadSchema } from '../../../../../Validations/UserValidations';
import { MICROSITE_INFO } from '../../../../../utils/constants';
import { createLeadGql } from '../../../../../store/schemas/location';
import { getClient } from '../../../../../init-apollo-googleFn';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { TextField } from '../../../../../shared_components/TextField';
import { UnitDropDown } from '../../../../../shared_components/UnitDropDown';
import { Loader } from 'semantic-ui-react'

function ContactForm() {
	const { location, locationId, units } = useContext(Context);
  const [toastMessage, setToastMessage] = useState(null);
  const vacantUnits = units.filter(a => a.node.status === "vacant");
  const orderedUnits = [...vacantUnits].sort((a, b) => a.node.number - b.node.number);
	const [ageCheck, setAgeCheck] = useState(false);
	const [loader, setLoader] = useState(false);

  const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
			setLoader(true);
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: obj.unit,
            }
          }
        }
      }).then(response => {
				setLoader(false);
				if (response.data.createLead.response === "200" && response.data.createLead.lead.id) {
					setToastMessage(toast.success("We've received your contact info. An agent will email you soon."));
				} else if (response.data.createLead.response !== "200" && response.data.createLead.lead.id) {
					setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
					setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e => console.log(e));
    }
    catch(e) {
			setLoader(false);
      console.log(e);
    }
  }

  	/* eslint-disable */
	useEffect(() => {
		return toastMessage;
	}, [toastMessage]);

  const leadFormSubmit = async(values) => {
    const isValid = await leadSchema.isValid();
		createLead(locationId, values);
  }
	/* eslint-enable */

	return (
		<div className="ltn__contact-message-area mb-120 mb--100">
			<ToastContainer position="bottom-center"/>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<Formik
							initialValues={{
								firstName: "",
								lastName: "",
								email: "",
								phoneNumber: "",
								unit: ""
							}}
							validationSchema={leadSchema}
							onSubmit={(values, {resetForm}) => {
								leadFormSubmit(values);
								resetForm();
							}}
						>
							{formik => (
								<div>
									<Form id="contact" className="contact-form-wrap contact-form-bg">
										<h4> Contact Us</h4>
										<TextField className='test' label="First Name" name="firstName" type="text" />
										<TextField label="Last Name" name="lastName" type="text" />
										<TextField label="Email" name="email" type="email" />
										<TextField label="Phone Number" name="phoneNumber" type="text" />
										<UnitDropDown label="Unit" name="unit" orderedUnits={orderedUnits} />
										<label style={{display: 'block', width: '240px', margin: '0 auto'}}>Are you at least 18 years of age?</label>
											<input
												onClick={()=>setAgeCheck(!ageCheck)}
												type="checkbox"
												style={{transform: 'scale(1.4, 1.4)', margin: '2px auto 0 auto', display: 'block', width: '17.23px'}}
											/>
										<div className="btn-wrap text-center">
										{loader ?
											<div style={{transform: 'scale(0.4)'}}>
												<Loader style={{margin: '0 auto'}}/>
											</div>
										:
											<button disabled={!ageCheck} style={{backgroundColor: "red", color: 'white'}} className='btn' type="click">Submit</button>
                    }
										</div>
									</Form>
								</div>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactForm