import gql from "graphql-tag";

export const googleEventsGql = gql`
  query googleEvents(
    $tourAgent: String
    $startDate: String
    $endDate: String
    $timezone: String
    $location: String!
  ) {
    googleEvents(
      tourAgent: $tourAgent
      fromDate: $startDate
      toDate: $endDate
      limit: 100
      timezone: $timezone
      location: $location
    ) {
      edges {
        node {
          id
          start
          end
        }
      }
    }
  }
`;

export const tourAgentsGql = gql`
  query tourAgents($location: String!) {
    tourAgents(location: $location) {
      edges {
        node {
          id
          dsPersonId
          locationId
        }
      }
    }
  }
`;
