import React from 'react';
import placeholderUnitPic from '../../../../../assets/undraw/undraw_apartment.png';
import Carousel from '../../../../../shared_components/Carousel';

function ProductSliderV1() {
	const selectedUnit = JSON.parse(localStorage.getItem('selectedUnit'));
	const photos = selectedUnit.node.photos;

	return (
		<div className="ltn__img-slider-area mb-90">
			<div className="container">
				{photos&&photos.length > 1 ?
					<Carousel photos={photos} />
				: 
					<div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
						<div className="col-lg-12">
							<img src={photos&&photos.length === 1 ? photos[0] : placeholderUnitPic} />
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default ProductSliderV1