import React, { useContext } from 'react';
import { Context } from '../../../../../AppContext';

function ServiceV2() {
	const { location } = useContext(Context);

	return (
		<div className="service-area" style={{backgroundColor: '#EBEEF3'}}>
			<div className="container" style={{padding: '40px'}}>
				<h2 style={{textAlign: 'center'}}>About Us</h2>
				<p>{location.description}</p>
			</div>
		</div>
	);
}

export default ServiceV2