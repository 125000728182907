import React, { useContext } from 'react';
import { Context } from '../../../../../AppContext';
import bannerVid from '../../../../../assets/banner/header-vid.webm';

function BannerV2() {
	const { location, photosArray } = useContext(Context);

  const inlineStyle = {
    backgroundImage: `url(${photosArray[0]})`
  }

	return (
		<>
			{/* <video autoPlay loop muted id='video' style={{position: 'absolute', width: '100vw'}}>
				<source src={bannerVid} type='video/mp4'/>
			</video> */}
			{/* Add to div below IF video: style={{height: '833.05px'}} */}
			<div className="banner-area banner-area-2 banner-area-bg" style={inlineStyle}>
				<div className="container">
					<div className="banner-area-inner">
						<div className="row justify-content-center">
							<div className="col-lg-8">
								<div className="banner-inner text-center">
									<h1 style={{color: "#fff"}}>{location.name}</h1>
									<div className="line" />
									<h2>The Best Way To Find Your Perfect {location.type === "Office" ? "Office" : "Home"}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BannerV2