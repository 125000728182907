import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';

function BannerV2() {
	const { location, photosArray } = useContext(Context);

	return (
		<>
			<div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
				<div className="ltn__slider-11-inner">
					<div className="ltn__slider-11-active">
						<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
							<div className="ltn__slide-item-inner">
								<div className="container">
									<div className="row">
										<div className="col-lg-12 align-self-center">
											<div className="slide-item-info">
												<div className="slide-item-info-inner">
													<h1 className="slide-title animated ">Search and Find <br /><span>Luxury</span> {location.type === "Office" ? "Offices" : "Homes"}</h1>
													<div className="slide-brief animated">
														<p>{location.description}</p>
													</div>
													<div className="btn-wrapper animated">
														<Link to="/contact" className="theme-btn-1 btn btn-effect-1">Make An Enquiry</Link>
													</div>
												</div>
											</div>
											<div className="slide-item-img">
												<img src={photosArray[0]} alt="#" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BannerV2