import gql from 'graphql-tag';

export const locationGql = gql`
  query location($locationId: String!) {
    location(locationId: $locationId){
      edges{
        node{
          id
          addresses
          services
          locationTypeId
          type
          templateId
          organizationId
          name
          description
          services
          applicationDocPath
          petPolicy
          petInformation
          status:
          totalUnits
          totalOccupied
          totalPrices
          totalBeds
          totalBaths
          totalSizes
          customId
          petsPermitted
          lat
          lng
          googlePlaceId
          walkScore
          photos
          amenities
          contact
          disabled
        }
      }
    }
  }
`;

export const unitsGql = gql`
  query units($locationId: String!) {
    units(locationId: $locationId) {
      edges {
        node {
          id
          locationId
          unitTypesId
          level
          building
          number
          description
          bathrooms
          bedrooms
          sqft
          price
          maxOccupancy
          petsPermitted
          photos
          amenities
          status
          unitType{
            type
          }
        }
      }
    }
  }
`;

export const unitGql = gql`
  query unit($unitId: String!){
    unit(unitId: $unitId) {
      edges {
        node {
          id
          unitType{
            bathrooms
          }
        }
      }
    }
  }
`;

export const unitTypeGql = gql`
  query unitType($locationId: String!) {
    unitType(locationId: $locationId) {
      edges {
        node {
          id
          type
          locationId
          bathrooms
          bedrooms
          photos
          sqft
          price
        }
      }
    }
  }
`;

export const orgDetailsGql = gql`
  query org($orgId: String!){
    org(orgId: $orgId) {
      edges {
        node {
          id
          phone
          businessEmail
          address
          name
        }
      }
    }
  }
`;

export const amenitiesGql = gql`
  query {
    amenities {
      edges {
        node {
          id
          name
          dbId
        }
      }
    }
  }
`;

export const createLeadGql = gql`
  mutation CreateLead($input: CrLeadInput!) {
    createLead(lead: $input) {
      response
      lead{
        id
      }
    }
  }
`;

export const createTourGql = gql`
  mutation CreateTour($input: LeadTourInput) {
    createTour(tour: $input) {
      response
    }
  }
`;


// Add FromDate
export const toursGql = gql`
  query Tours($location: String!){
    tours(location: $location) {
      edges {
        node {
          id
          status
          date
          time
          duration
          name
          agent {
            id
          }
        }
      }
    }
  }
`;

export const tourTypesGql = gql`
  query TourTypes ($location: String!){
    tourTypes(location: $location) {
      edges {
        node {
          id
          code
          name
          duration
          tourType
          connectionInfo
          selfBooking
        }
      }
    }
  }
`;

export const createApplication = gql`
  mutation CreateApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      response
    }
  }
`;

export const savedInfoPaymentMutationGql = gql`
  mutation publicChargePaymentMethod($paymentMethod: ChargePaymentMethodInput!) {
    publicChargePaymentMethod(paymentMethod: $paymentMethod) {
      response
    }
  }
`;