import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button, Header, Divider, Label, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'

function Occupants(props) {
    const [coAppAdd, setCoAppAdd] = useState(false);
    const [coOccupantsAdd, setOccupantsAdd] = useState(false);
    const [coPetsAdd, setPetsAdd] = useState(false);
    const [OccupantInfo, setOccupantInfo] = useState({
        CoApplicant: [],
        Occupants: [],
        Pets: []
    });

    // Effect Handler to Local Storage
    useEffect(() => {
        if (performance.navigation.type === 1) {
            const OccupantInfo = localStorage.getItem('OccupantInfo')
            const coAppAdd = localStorage.getItem('coAppAdd')
            const coOccupantsAdd = localStorage.getItem('coOccupantsAdd')
            const coPetsAdd = localStorage.getItem('coPetsAdd')
            OccupantInfo &&setCoAppAdd(coAppAdd)
            coAppAdd &&setOccupantsAdd(coOccupantsAdd)
            coOccupantsAdd &&setPetsAdd(coPetsAdd)
            coPetsAdd &&setOccupantInfo(JSON.parse(OccupantInfo))
        } else {
            const OccupantInfo = localStorage.getItem('OccupantInfo')
            const coAppAdd = localStorage.getItem('coAppAdd')
            const coOccupantsAdd = localStorage.getItem('coOccupantsAdd')
            const coPetsAdd = localStorage.getItem('coPetsAdd')
            OccupantInfo &&setCoAppAdd(coAppAdd)
            coAppAdd &&setOccupantsAdd(coOccupantsAdd)
            coOccupantsAdd &&setPetsAdd(coPetsAdd)
            coPetsAdd &&setOccupantInfo(JSON.parse(OccupantInfo))
        }
    }, []);
    // Effect handler to set local storage
    useEffect(() => {
        const itemToSave = JSON.stringify(OccupantInfo)
        localStorage.setItem('coPetsAdd', coPetsAdd)
        localStorage.setItem('coAppAdd', coAppAdd)
        localStorage.setItem('coOccupantsAdd', coOccupantsAdd)
        if (Object.keys(itemToSave).length !== 0) {
            localStorage.setItem('OccupantInfo', itemToSave)
        }
    }, [OccupantInfo]);



    const addCoAppLocation = () => {
        const newObj = {
            "firstName": '',
            "lastName": '',
            "email": '',
            "phoneMobile": '',
        }
        if (!OccupantInfo.CoApplicant || OccupantInfo.CoApplicant.length == 0) {
            setOccupantInfo(prev => ({
                ...prev,
                CoApplicant: [newObj]
            }))
        }
        else {
            setOccupantInfo({
                ...OccupantInfo,
                CoApplicant: [...OccupantInfo.CoApplicant, newObj]
            })

        }
    }
    const addPetsLocation = () => {
        const newObj = {
            "name": '',
            "breed": '',
            "weight": '',
            "age": '',
        }
        if (!OccupantInfo.Pets || OccupantInfo.Pets.length == 0) {
            setOccupantInfo(prev => ({
                ...prev,
                Pets: [newObj]
            }))
        }
        else {
            setOccupantInfo({
                ...OccupantInfo,
                Pets: [...OccupantInfo.Pets, newObj]
            })

        }
    }
    const addOccupantsLocation = () => {
        const newObj = {
            "firstName": '',
            "lastName": '',
        }
        if (!OccupantInfo.Occupants || OccupantInfo.Occupants.length == 0) {
            setOccupantInfo(prev => ({
                ...prev,
                Occupants: [newObj]
            }))
        }
        else {
            setOccupantInfo({
                ...OccupantInfo,
                Occupants: [...OccupantInfo.Occupants, newObj]
            })

        }
    }

    const removeCoAppLocation = (index) => {
        var { CoApplicant } = OccupantInfo
        var newCharges = OccupantInfo.CoApplicant.filter((charge, index2) => index2 !== index);
        CoApplicant = newCharges
        setOccupantInfo(prev => ({
            ...prev,
            CoApplicant: CoApplicant
        }))
        // setOccupantInfo({ CoApplicant })
        if (CoApplicant.length == 0) {
            setCoAppAdd(false)
        }
    }
    const removeOccLocation = (index) => {
        var { Occupants } = OccupantInfo
        var newCharges = OccupantInfo.Occupants.filter((charge, index2) => index2 !== index);
        Occupants = newCharges
        setOccupantInfo(prev => ({
            ...prev,
            Occupants: Occupants
        }))
        // setOccupantInfo({ Occupants })
        if (Occupants.length == 0) {
            setOccupantsAdd(false)
        }
    }
    const removePetsLocation = (index) => {
        var { Pets } = OccupantInfo
        var newCharges = OccupantInfo.Pets.filter((charge, index2) => index2 !== index);
        Pets = newCharges
        setOccupantInfo(prev => ({
            ...prev,
            Pets: Pets
        }))
        // setOccupantInfo({ Pets })
        if (Pets.length == 0) {
            setPetsAdd(false)
        }
    }


    const handleChangeCo = (e, index, name) => {
        const { CoApplicant } = OccupantInfo;
        CoApplicant[index] = {
            ...CoApplicant[index],
            [name]: e.target.value
        };
        setOccupantInfo(prev => ({
            ...prev,
            CoApplicant: CoApplicant
        }))
        // setOccupantInfo(...OccupantInfo,{ CoApplicant })
    };
    const handleChangeOcc = (e, index, name) => {
        const { Occupants } = OccupantInfo;
        Occupants[index] = {
            ...Occupants[index],
            [name]: e.target.value
        };
        setOccupantInfo(prev => ({
            ...prev,
            Occupants: Occupants
        }))
    };
    const handleChangePets = (e, index, name) => {
        const { Pets } = OccupantInfo;
        Pets[index] = {
            ...Pets[index],
            [name]: e.target.value
        };
        setOccupantInfo(prev => ({
            ...prev,
            Pets: Pets
        }))
    };
    const Stepper = () => {
        props.setDataObject(prevState => ({ ...prevState, Page4: OccupantInfo }))
        props.setAppStep(5)
    }

    const disabledCheck = () => {
        if (OccupantInfo&&OccupantInfo.Occupants.length == 0 && OccupantInfo.Pets.length == 0 && OccupantInfo.CoApplicant.length == 0) {
            return false
        }
        else {
            if (OccupantInfo&&OccupantInfo.Pets.length >= 1) {
                if (OccupantInfo.Pets.every(item => item.breed)) {
                    return false
                }
                else return true
            }
            if (OccupantInfo&&OccupantInfo.Occupants.length >= 1) {
                if (OccupantInfo.Occupants.every(item => item.firstName && item.lastName)) {
                    return false
                }
                else return true
            }
            if (OccupantInfo&&OccupantInfo.CoApplicant.length >= 1) {
                if (OccupantInfo.CoApplicant.every(item => item.firstName && item.lastName)) {
                    return false
                }
                else return true
            }
        }
    }
    return (
        <>
            <div>
                <>
                    <div>
                        <h1 class="section__heading">Occupant Information</h1>
                        <p>Check all that apply.</p>

                        <Form>
                            <Segment style={{ background: 'lightyellow' }}>Co-Applicants are other individuals who are age 18 or older that will be residing in this unit/house. Each Co-Applicant must submit their own application and will sign the lease. Other Occupants are individuals who will live in the unit/house but will not sign the lease (ex. a minor or child).</Segment>
                            <Form.Field>
                                <Header>I am applying with Co-Applicants who will be signing the lease</Header>
                                {/* <Checkbox name="noMiddle" label='I am applying with Co-Applicants who will be signing the lease' checked={coAppAdd} onChange={() => setCoAppAdd(!coAppAdd ? true : false)} /> */}
                            </Form.Field>

                            {
                               OccupantInfo&& OccupantInfo.CoApplicant && OccupantInfo.CoApplicant.map((app, index) => {
                                    return (
                                        <>
                                            <Form>
                                                <Header>Co-App</Header>
                                                <Form.Field required>
                                                    <label>First Name</label>
                                                    <input name={`firstName`} value={app.firstName} placeholder='First Name' onChange={(e) => handleChangeCo(e, index, "firstName")} />
                                                </Form.Field>
                                                <Form.Field required>
                                                    <label>Last Name</label>
                                                    <input name={`lastName`} value={app.lastName} placeholder='Last Name' onChange={(e) => handleChangeCo(e, index, "lastName")} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Email</label>
                                                    <input name={`email`} value={app.email} placeholder='Email' onChange={(e) => handleChangeCo(e, index, "email")} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Phone</label>
                                                    <input name={`phoneMobile`} value={app.phoneMobile} placeholder='Phone' onChange={(e) => handleChangeCo(e, index, "phoneMobile")} />
                                                </Form.Field>
                                            </Form>
                                            <Label as={'button'} onClick={() => removeCoAppLocation(index)}>
                                                <Icon name='minus' /> Remove Co App
                                            </Label>
                                            <Divider />

                                        </>
                                    )
                                })
                            }



                            <>
                                <Label as={'button'} onClick={() => addCoAppLocation()}>
                                    <Icon name='add' /> Add Co App
                                </Label>
                            </>

                            <Divider />
                            <Form.Field>
                                <Header>I will have other occupants living with me who will not be signing the lease</Header>
                                {/* <Checkbox name="noMiddle" label='I will have other occupants living with me who will not be signing the lease' checked={coOccupantsAdd} onChange={() => setOccupantsAdd(!coOccupantsAdd ? true : false)} /> */}
                            </Form.Field>

                            <>
                                {
                                   OccupantInfo&& OccupantInfo.Occupants && OccupantInfo.Occupants.map((app, index) => {
                                        return (
                                            <>
                                                <Form>
                                                    <Header>Occupant</Header>
                                                    <Form.Field required>
                                                        <label>First Name</label>
                                                        <input name={`firstName`} value={app.firstName} placeholder='First Name' onChange={(e) => handleChangeOcc(e, index, "firstName")} />
                                                    </Form.Field>
                                                    <Form.Field required>
                                                        <label>Last Name</label>
                                                        <input name={`lastName`} value={app.lastName} placeholder='Last Name' onChange={(e) => handleChangeOcc(e, index, "lastName")} />
                                                    </Form.Field>
                                                </Form>
                                                <Label as={'button'} onClick={() => removeOccLocation(index)}>
                                                    <Icon name='minus' /> Remove Occupant
                                                </Label>
                                                <Divider />
                                            </>
                                        )
                                    })
                                }
                            </>
                            <>
                                <Label as={'button'} onClick={() => addOccupantsLocation()}>
                                    <Icon name='add' /> Add Occupant
                                </Label>
                            </>


                            <Divider />
                            <Form.Field>
                                <Header>I have pets</Header>
                                {/* <Checkbox name="noMiddle" label=' ' checked={coPetsAdd} onChange={() => setPetsAdd(!coPetsAdd ? true : false)} /> */}
                            </Form.Field>

                            {
                               OccupantInfo&& OccupantInfo.Pets && OccupantInfo.Pets.map((app, index) => {
                                    return (
                                        <>
                                            <Form>
                                                <Header>Pet</Header>
                                                <Form.Field>
                                                    <label>Name</label>
                                                    <input name={`name`} value={app.name} placeholder='Name' onChange={(e) => handleChangePets(e, index, "name")} />
                                                </Form.Field>
                                                <Form.Field required>
                                                    <label>Breed</label>
                                                    <input name={`breed`} value={app.breed} placeholder='Breed' onChange={(e) => handleChangePets(e, index, "breed")} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Weight(lbs)</label>
                                                    <input name={`weight`} value={app.weight} placeholder='Weight' onChange={(e) => handleChangePets(e, index, "weight")} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Age</label>
                                                    <input name={`age`} value={app.age} placeholder='Age' onChange={(e) => handleChangePets(e, index, "age")} />
                                                </Form.Field>
                                            </Form>
                                            <Label as={'button'} onClick={() => removePetsLocation(index)}>
                                                <Icon name='minus' /> Remove Pet
                                            </Label>
                                            <Divider />
                                        </>
                                    )
                                })
                            }

                            <>
                                <Label as={'button'} onClick={() => addPetsLocation()}>
                                    <Icon name='add' /> Add Pet
                                </Label>
                            </>


                        </Form>
                        <br></br>
                        <div style={{ float: 'right' }}>
                            <Button icon labelPosition='left' onClick={() => props.setAppStep(3)}>
                                <Icon name='left arrow' />
                                Previous
                            </Button>
                            <Button icon labelPosition='right' disabled={disabledCheck()} onClick={() => Stepper()}>
                            Save & Next
                                <Icon name='right arrow' />
                            </Button>
                        </div>

                    </div>
                </>
            </div>
        </>
    );
}

export default Occupants