import React, { useContext, useState } from 'react';
import { Context } from '../../../../../AppContext';
import placeholderFloorPlan from '../../../../../assets/undraw/undraw_plan.png';


function ApartmentV2(props) {
	const { unitType } = useContext(Context);
	const [selectedUnitType, setSelectedUnitType] = useState(0);

  return (
		<div className={"ltn__apartments-plan-area pt-115--- pb-70 "}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h1 className="section-title" style={{marginTop: '40px'}}>Floor Plans</h1>
						</div>
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
							<div className="nav">
								{unitType.map((k,i) => {
									return (
										<a className={`${i === selectedUnitType && 'active show'}`} onClick={() => setSelectedUnitType(i)}>{k.node.type}</a>
									);
								})}
							</div>
						</div>
						<div className="tab-content">
							{unitType.map((k,i) => {
								const { node } = k;
								const photo = (node.photos !== "\"\"" && node.photos !== null) ? JSON.parse(JSON.parse(node.photos)) : [placeholderFloorPlan];
								return (
									i === selectedUnitType &&
									<div className="tab-pane fade active show" id="liton_tab_3_2">
										<div className="ltn__product-tab-content-inner">
											<div className="row">
												<div className="col-lg-6">
													<div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
														<h2>{node.type}</h2>
														<div className="apartments-info-list apartments-info-list-color mt-40">
															<ul>
																<li><label>Total Area</label> <span>{node.sqft} Sq. Ft</span></li>
																<li><label>Bedrooms</label> <span>{node.bedrooms}</span></li>
																<li><label>Bathrooms</label> <span>{node.bathrooms}</span></li>
															</ul>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="apartments-plan-img">
														<img src={photo[0]} alt="#" />
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ApartmentV2