import React, { useContext, useState, useEffect } from 'react';
import { Icon, Step, Grid, Form, Checkbox, Button, Header, Divider, Label, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './Application.css'
import { optionsCountry, optionsState, optionsMonthNumber, optionsYear, formatPhoneNumber } from '../../utils/constants.js'
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

function Lived(props) {
    const [livedPage, setLivedPage] = useState({
        rentalHistory: [

        ],
        references: [

        ]
    });
    // Effect Handler to Local Storage
    useEffect(() => {
        if (performance.navigation.type === 1) {
            const savedData = localStorage.getItem('livedPage')
            if (savedData) {
                setLivedPage(JSON.parse(savedData))
            }
        } else {
            const savedData = localStorage.getItem('livedPage')
            if (savedData) {
                setLivedPage(JSON.parse(savedData))
            }
        }
    }, []);
    // Effect handler to set local storage
    useEffect(() => {
        const itemToSave = JSON.stringify(livedPage)
        if (livedPage && Object.keys(livedPage).length !== 0) {
            localStorage.setItem('livedPage', itemToSave)
        }
    }, [livedPage]);


    const handleChangeLocation = (e, index, name) => {
        const { rentalHistory } = livedPage;
        rentalHistory[index] = {
            ...rentalHistory[index],
            [name]: e.target ? e.target.value : e
        };
        console.log(e, index, name)
        setLivedPage(prev => ({
            ...prev,
            rentalHistory: rentalHistory
        }))
        // setLivedPage({ rentalHistory })
    };
    const handleChangeRef = (e, index, name) => {
        const { references } = livedPage;
        references[index] = {
            ...references[index],
            [name]: e.target.value
        };
        setLivedPage(prev => ({
            ...prev,
            references: references
        }))
        // setLivedPage({ references })
    };



    const addLivingLocation = () => {
        const newObj = {
            "streetLine1": '',
            "streetLine2": '',
            "city": '',
            "state": '',
            "postcode": '',
            "country": '',
            "month": '',
            "year": '',
            "monthlyRent": '',
            "landlordName": '',
            "landlordPhone": '',
            "landlordEmail": '',
            "reasonForLeaving": ''
        }
        if (!livedPage.rentalHistory || livedPage.rentalHistory.length == 0) {
            setLivedPage(prev => ({
                ...prev,
                rentalHistory: [newObj]
            }))
        }
        else {
            setLivedPage({
                ...livedPage,
                rentalHistory: [...livedPage.rentalHistory, newObj]
            })

        }

    }

    const addRefLocation = () => {
        const newObj = {
            "name": '',
            "address": '',
            "relationship": '',
            "mobilePhone": '',

        }
        if (!livedPage.references || livedPage.references.length == 0) {
            setLivedPage(prev => ({
                ...prev,
                references: [newObj]
            }))
        }
        else {
            setLivedPage({
                ...livedPage,
                references: [...livedPage.references, newObj]
            })

        }

    }

    const Stepper = () => {
        props.setDataObject(prevState => ({ ...prevState, Page3: livedPage }))
        props.setAppStep(4)
    }

    const removeLivingLocation = (index) => {
        var { rentalHistory } = livedPage
        var newCharges = livedPage.rentalHistory.filter((charge, index2) => index2 !== index);
        rentalHistory = newCharges
        setLivedPage(prev => ({
            ...prev,
            rentalHistory: rentalHistory
        }))
    }

    const removeRefLocation = (index) => {
        var { references } = livedPage
        var newCharges = livedPage.references.filter((charge, index2) => index2 !== index);
        references = newCharges
        setLivedPage(prev => ({
            ...prev,
            references: references
        }))
    }

    const disabledCheck = () => {
        if (livedPage && livedPage.rentalHistory && !livedPage.rentalHistory[0]) {
            return true
        }
        if (livedPage && livedPage.rentalHistory && livedPage.rentalHistory[0]) {
            if (!livedPage.rentalHistory[0].streetLine1 || !livedPage.rentalHistory[0].state || !livedPage.rentalHistory[0].city || !livedPage.rentalHistory[0].postcode || !livedPage.rentalHistory[0].country || !livedPage.rentalHistory[0].month || !livedPage.rentalHistory[0].year) {
                return true
            }
            if (!livedPage.rentalHistory[0].streetLine1.trim().length === 0 || !livedPage.rentalHistory[0].state.trim().length === 0 || !livedPage.rentalHistory[0].postcode.trim().length === 0 || !livedPage.rentalHistory[0].country.trim().length === 0) {
                return true
            }
            else return false
        }
        else return true
    }


    return (
        <>
            <div>
                <h1 class="section__heading">Where you Lived</h1>
                <p>Please provide the information.
                </p>
                <Segment style={{ background: 'lightyellow' }}>Please list at least one address.  Renters are encouraged to list at least 3 years of residence.</Segment>
                <Header>Address</Header>
                {
                    livedPage && livedPage.rentalHistory && livedPage.rentalHistory.map((location, index) => {
                        return (
                            <>
                                <Form>
                                    <Form.Field required>
                                        <label>Address 1</label>
                                        <input name={`streetLine1`} value={location.streetLine1} placeholder='Address' onChange={(e) => handleChangeLocation(e, index, "streetLine1")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Address 2</label>
                                        <input name={`streetLine2`} value={location.streetLine2} placeholder='Unit' onChange={(e) => handleChangeLocation(e, index, "streetLine2")} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>City</label>
                                        <input name={`city`} value={location.city} placeholder='City' onChange={(e) => handleChangeLocation(e, index, "city")} />
                                    </Form.Field>
                                    <Form.Select
                                        fluid
                                        required
                                        search
                                        label='State'
                                        name="state"
                                        options={optionsState}
                                        value={location.state}
                                        placeholder="State"
                                        onChange={(e, { value }) => handleChangeLocation(value, index, "state",)}
                                    // onChange={(e, { value }) => handleChangeSelect("state", value)}
                                    />
                                    {/* <Form.Field required>

                                        <label>State</label>
                                        <input value={location.state} name={`state`} placeholder='State' onChange={(e) => handleChangeLocation(e, index, "state")} />
                                    </Form.Field> */}
                                    <Form.Field required>
                                        <label>Zip</label>
                                        <input onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} name={`postcode`} placeholder='Zip' value={location.postcode} onChange={(e) => handleChangeLocation(e, index, "postcode")} />
                                    </Form.Field>
                                    <Form.Select
                                        required
                                        fluid
                                        search
                                        label='Country'
                                        name={`country`}
                                        options={optionsCountry}
                                        value={location.country}
                                        placeholder="Country"
                                        onChange={(e, { value }) => handleChangeLocation(value, index, "country",)}
                                    />
                                    {/* This is one field not 2 */}
                                    <Form.Field widths="equal">
                                        <Form.Select
                                            fluid
                                            required
                                            label='Reside from Month'
                                            name={`month`}
                                            options={optionsMonthNumber}
                                            value={location.month}
                                            placeholder="Month"
                                            onChange={(e, { value }) => handleChangeLocation(value, index, "month",)}
                                        />
                                        <Form.Select
                                            fluid
                                            required
                                            label='Reside from Year'
                                            name={`year`}
                                            options={optionsYear}
                                            value={location.year}
                                            placeholder="Year"
                                            onChange={(e, { value }) => handleChangeLocation(value, index, "year",)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Monthly Rent</label>
                                        <input name={`monthlyRent`} placeholder='Rent' value={location.monthlyRent} onChange={(e) => handleChangeLocation(e, index, "monthlyRent")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Landlord</label>
                                        <input name={`landlordName`} placeholder='Name' value={location.landlordName} onChange={(e) => handleChangeLocation(e, index, "landlordName")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Landlord Phone</label>
                                        <input maxlength="10" onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} name={`landlordPhone`} placeholder='Phone' value={formatPhoneNumber(location.landlordPhone)} onChange={(e) => handleChangeLocation(e, index, "landlordPhone")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Landlord Email</label>
                                        <input name={`landlordEmail`} placeholder='Email' value={location.landlordEmail} onChange={(e) => handleChangeLocation(e, index, "landlordEmail")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Reason For Leaving</label>
                                        <input name={`reasonForLeaving`} placeholder='Reason' value={location.reasonForLeaving} onChange={(e) => handleChangeLocation(e, index, "reasonForLeaving")} />
                                    </Form.Field>
                                </Form>
                                <Label as={'button'} onClick={() => removeLivingLocation(index)}>
                                    <Icon name='minus' /> Remove Previous
                                </Label>
                                <Divider />
                            </>
                        )
                    })
                }

                <Label as={'button'} onClick={() => addLivingLocation()}>
                    <Icon name='add' /> Add Address
                </Label>
                <Divider />


                <Header>Reference</Header>
                {
                    livedPage && livedPage.references && livedPage.references.map((ref, index) => {
                        return (
                            <>
                                <Form>
                                    <Form.Field>
                                        <label>Reference Name</label>
                                        <input name={`name`} placeholder='Reference Name' value={ref.name} onChange={(e) => handleChangeRef(e, index, "name")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Address</label>
                                        <input name={`address`} placeholder='Address' value={ref.address} onChange={(e) => handleChangeRef(e, index, "address")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Relationship</label>
                                        <input name={`relationship`} placeholder='Relationship' value={ref.relationship} onChange={(e) => handleChangeRef(e, index, "relationship")} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Phone</label>
                                        <input maxlength="10" onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} name={`mobilePhone`} placeholder='Phone' value={formatPhoneNumber(ref.mobilePhone)} onChange={(e) => handleChangeRef(e, index, "mobilePhone")} />
                                    </Form.Field>
                                </Form>
                                <Label as={'button'} onClick={() => removeRefLocation(index)}>
                                    <Icon name='minus' /> Remove Reference
                                </Label>
                                <Divider />
                            </>)
                    })
                }
                <Label as={'button'} onClick={() => addRefLocation()}>
                    <Icon name='add' /> Add Reference
                </Label>
                <Divider />
                <br></br>
                <div style={{ float: 'right' }}>
                    <Button icon labelPosition='left' onClick={() => props.setAppStep(2)}>
                        <Icon name='left arrow' />
                        Previous
                    </Button>
                    <Button icon labelPosition='right' disabled={disabledCheck()} onClick={() => Stepper()}>
                        Save & Next
                        <Icon name='right arrow' />
                    </Button>
                </div>

            </div>
        </>
    );
}

export default Lived