import React from 'react';
import Navbar from './global-components/navbar-v3';
import Banner from './section-components/banner-v2';
import About from './section-components/about';
import Property from './section-components/property';
import Footer from './global-components/footer';

const Home_V2 = () => {
    return <div>
        <Navbar />
        <Banner />
        <About/>
        <Property />
        <Footer />
    </div>
}

export default Home_V2

